import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedPage } from "../../reducers/favouritesSlice";
import { pages } from "../../utils/helper";
import assetLogo from "../assets/assetLogo.png";
import { NavbarStyles } from "./StylesNavBar";

const DesktopNavbar = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const selectedPage = useSelector((state) => state.favourites.selectedPage);

  const handleRouteChange = (page) => {
    dispatch(setSelectedPage(page.name));
    router(`/${page.route}`);
  };

  return (
    <Box sx={NavbarStyles.desktopRoot}>
      <Box sx={{ ...NavbarStyles.align, gap: "2rem" }}>
        <Box onClick={() => handleRouteChange({ name: "Home", route: "" })}>
          <img src={assetLogo} alt={"Logo"} height={40} />
        </Box>
        <Box sx={{ ...NavbarStyles.align, alignItems: "center" }}>
          {pages.map((page, index) => (
            <Box
              key={index}
              sx={{
                marginInline: "0.75rem",
                color: page.name === selectedPage ? "#2C9763" : "#000",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: page.name === selectedPage ? 700 : 500,
                cursor: "pointer",
              }}
              onClick={() => handleRouteChange(page)}
            >
              {page.name}
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ ...NavbarStyles.align, justifySelf: "flex-end" }}>
        <IconButton
          onClick={() => {
            dispatch(setSelectedPage("Favourites"));
            router("/favourites");
          }}
        >
          <FavoriteBorderIcon
            sx={{
              color: selectedPage === "Favourites" ? "#2C9763" : "#000000",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            dispatch(setSelectedPage("Cart"));
            router("/cart");
          }}
        >
          <ShoppingCartOutlinedIcon
            sx={{
              color: selectedPage === "Cart" ? "#2C9763" : "#000000",
            }}
          />
        </IconButton>
        <IconButton>
          <PermIdentityOutlinedIcon
            sx={{
              color: "#000000",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DesktopNavbar;
