import { Box, IconButton, Typography, styled, Button } from "@mui/material";

export const StHeadingTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isNotDesktop",
})(({ theme, isNotDesktop }) => ({
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#30363C",
  fontSize: isNotDesktop ? "1.3rem" : "2rem",
  lineHeight: isNotDesktop ? "30px" : "44px",
}));

export const StImage = styled(`img`)(({ theme }) => ({
  width: "100%",
}));

export const StTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isNotDesktop",
})(({ theme, isNotDesktop }) => ({
  color: "#30363C",
  fontSize: isNotDesktop ? "0.8rem" : "1rem",
  fontWeight: 400,
  lineHeight: isNotDesktop ? "24px" : "30px",
}));

export const StFooterLinks = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  marginBlock: "0.5rem",
  color: "#EBEEF1",
  fontFamily: "Poppins",
  fontSize: "0.9rem",
  fontWeight: 500,
}));

export const StSecondaryFooterLinks = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  marginBlock: "0.5rem",
  color: "#6C7882",
  fontFamily: "Poppins",
  fontSize: "0.9rem",
  fontWeight: 500,
}));

export const StFooterIcons = styled(IconButton)(({ theme }) => ({
  cursor: "pointer",
  marginBlock: "0.5rem",
  color: "#6C7882",
  padding: 0,
}));

export const StContainer = styled(Box)(({ theme }) => ({
  // marginInline: "4rem",
  [theme.breakpoints.down("md") && theme.breakpoints.up("sm")]: {
    // marginInline: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    // marginInline: "1rem",
  },
}));

export const StBlockContainer = styled(Box)(({ theme }) => ({
  // marginBlock: "2rem",
  // [theme.breakpoints.down("md")]: {
  //   marginBlock: "1rem",
  // },
}));

export const StButton = styled(Button)(({ theme, height }) => ({
  variant: "contained",
  background: "#2C9763",
  height: height,
  width: "100%",
  fontWeight: "600",
  borderRadius: "0px",
  color: "#fff",
  textTransform: "none",
  "&:hover": {
    background: "#0d683b",
    // color: "#80b500",
  },
}));
