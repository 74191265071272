export const benefitsStyles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "16px",
    height: "70px",
    width: "271px",
    paddingX: "4px",
    border: `1px solid #2C9763`,
    filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3))",
  },
  rootMobile: {
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "16px",
    height: "70px",
    width: "174px",
    border: `1px solid #2C9763`,
    boxShadow: "5px 5px 11px rgba(0, 0, 0, 0.3)",
  },
};
