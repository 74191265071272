import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { disclaimerData } from "./disclaimerData";
import { useMediaQuery, useTheme } from "@mui/material";

export const Disclaimer = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <PolicyComponent data={disclaimerData} header="Disclaimer" noHeaderSpace />
    </AppLayout>
  );
};
