import { green } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React from "react";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: green[500],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: green[500],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: green[500],
          },
        },
      },
    },
  },
});

export const CustomTextField = (props) => {
  const { placeholder, label, variant, InputProps, ...rest } = props;
  return (
    <ThemeProvider theme={theme}>
      <TextField
        placeholder={placeholder}
        label={label}
        variant={variant}
        InputProps={InputProps}
        size="small"
        {...rest}
      />
    </ThemeProvider>
  );
};
