import React from "react";
import { Outlet } from "react-router-dom";
import { useScrollToTop } from "../../../utils";

export const Layout = () => {
  useScrollToTop();

  return (
    <>
      <Outlet />
    </>
  );
};

