import React from "react";
import Carousel from "react-material-ui-carousel";
import image1 from "../../assets/bannerImg2.png";
import image2 from "../../assets/bannerImg1.png";
import mblImage1 from "../../assets/bannerMblImg1.png";
import mblImage2 from "../../assets/bannerMblImg2.png";
import CarouselItem from "./CarousalItem";

export const CustomCarousel = () => {
  var bannerItems = [
    {
      name: "Authentic Palm Jaggery",
      description: "Straight from the heart of Tamil Nadu  ",
      image: image1,
      mblImage: mblImage1,
      textPosition: "right",
    },
    {
      name: "Authentic Palm Jaggery",
      description: "Straight from the heart of Tamil Nadu ",
      image: image2,
      mblImage: mblImage2,
      textPosition: "left",
    },
  ];

  return (
    <>
      <Carousel indicators={false}>
        {bannerItems.map((item, i) => (
          <CarouselItem key={i} item={item} />
        ))}
      </Carousel>
    </>
  );
};
