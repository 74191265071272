import { Box } from "@mui/material";
import React from "react";
import { cardData } from "../../../utils/helper";
import { ProductCard, SlickCarouselComponent } from "../../components";
import { StHeadingTypography } from "../../Componentstyles";
import { LandingPageStles } from "./LandPageStyles";

export const ProductSection = (props) => {
  const { isNotDesktop, isMobile } = props;
  // const tabItems = [
  //   {
  //     label: "Karupatti",
  //     items: cardData.filter((item) => item.category === "karupatti"),
  //   },
  //   {
  //     label: "Rice",
  //     items: cardData.filter((item) => item.category === "rice"),
  //   },
  //   {
  //     label: "Cold Pressed oil",
  //     items: cardData.filter((item) => item.category === "oil"),
  //   },
  //   {
  //     label: "Health Mix",
  //     items: cardData.filter((item) => item.category === "healthmix"),
  //   },
  // ];

  // const [value, setValue] = useState(0);
  // const [items, setItems] = useState(tabItems[0].items);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   setItems(tabItems[newValue].items);
  // };

  return (
    <Box
      sx={
        isNotDesktop
          ? LandingPageStles.productSectionRootMobile
          : LandingPageStles.productSectionRoot
      }
    >
      <StHeadingTypography
        isNotDesktop={isMobile}
        sx={
          isMobile ? LandingPageStles.headersMobile : LandingPageStles.headers
        }
      >
        Our Products
      </StHeadingTypography>
      <Box
        sx={{ padding: isMobile ? "0rem 1rem 0rem" : "0rem 1rem" }}
      >
      <SlickCarouselComponent
        // autoplay={true}
        children={cardData.map((item, index) => (
          <ProductCard key={index} item={item} isMobile={isMobile} />
        ))}
      />
      </Box>
      {/* <Box sx={{ width: isMobile ? "100%" : "90%", margin: "auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          sx={isMobile ? LandingPageStles.tabsMobile : LandingPageStles.tabs}
          variant="scrollable"
          // scrollButtons="auto"
        >
          {tabItems.map((tab, index) => (
            <Tab
              label={tab.label}
              key={index}
              sx={{
                ...LandingPageStles.productTab,
                color: value === index ? "#2C9763 !important" : "#000000",
              }}
            />
          ))}
        </Tabs>
        {tabItems.map((tab, index) => (
          <TabPanel value={value} index={index} key={index} isMobile={isMobile}>
            <Grid container spacing={isMobile ? 2 : 4}>
              {tab.items.map(
                (item, itemIndex) =>
                  itemIndex < 4 && (
                    <Grid item key={itemIndex} xs={6} sm={6} md={3} lg={3}>
                      <ProductCard item={item} isMobile={isMobile} />
                    </Grid>
                  )
              )}
            </Grid>
          </TabPanel>
        ))}
      </Box> */}
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, isMobile, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: isMobile ? 0 : 3, py: isMobile ? 1.5 : 3, pb: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
