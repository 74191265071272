export const productStyles = {
  contentSide: {
    margin: 0,
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  productName: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#30363C",
    fontWeight: 600,
  },
  productPrice: {
    fontSize: "36px",
    color: "#2C9763",
    fontWeight: "bold",
    lineHeight: "36px",
  },
  discountPrice: {
    fontSize: "23px",
    lineHeight: "36px",
    color: "#000000",
    fontWeight: 400,
    textDecoration: "line-through",
  },
  divider: {
    borderColor: "#f0f0f0",
    marginBlock: "0.5rem",
  },
  productDescriptionSpan: {
    fontSize: "12px",
    display: "block",
    fontWeight: "500",
  },
  button: {
    background: "#2C9763",
    height: "42px",
    width: "100%",
    borderRadius: "8px",
    fontWeight: "600",
    "&:hover": {
      background: "#2C9763",
    },
    maxWidth: "200px",
  },
  iconButton: {
    color: "#CED5DB",
    borderRadius: "5px",
    border: "1px solid #CED5DB",
    padding: "5px",
  },
  iconButtonContainer: {
    padding: "0.5rem",
    margin: "0px",
    display: "flex",
    gap: "1rem",
  },
  contentValue: {
    fontSize: "16px",
    lineHeight: "1rem",
    color: "#000",
    fontFamily: "Poppins",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
  },
  contentLabel: {
    fontSize: "14px",
    color: "#000",
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: "1rem",
    width: "120px",
  },
  productTab: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C9763 !important",
    fontWeight: 600,
    textTransform: "none",
  },
};
