import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  setCart,
  setFavourites,
  setSelectedPage,
} from "../../../reducers/favouritesSlice";
import {
  getCategoryListingData,
  getProductById,
  pages,
} from "../../../utils/helper";
import { AppLayout, CustomBreadcrumbsNavigation } from "../../components";
import BasicTabs from "./CustomTabPanel";
import "./ImageGallery.css"; // Import your custom CSS file
import { productStyles } from "./productStyles";

const images = [
  {
    original: "https://picsum.photos/id/1018/1000/600/",
    thumbnail: "https://picsum.photos/id/1018/250/150/",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export const ProductDescriptionPage = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down(1200));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { productId } = useParams();
  const dispatch = useDispatch();
  const router = useNavigate();
  const selectedPage = useSelector((state) => state.favourites.selectedPage);

  const [isFavourite, setIsFavourite] = useState(false);
  const [isCart, setIsCart] = useState(false);
  const reducerData = useSelector((state) => state.favourites);
  const [productData, setProductData] = useState({});
  const [breadCrums, setBreadCrums] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    setIsFavourite(reducerData?.favouriteIds?.includes(productId));
    setIsCart(reducerData?.cartIds?.includes(productId));
    const product = getProductById(productId);
    setProductData(product);
    let crumbs = pages.filter((page) => page?.name === selectedPage);
    crumbs.push({
      name: product?.productName,
      route: `product/${productId}`,
    });
    const categoryId = crumbs?.[0]?.route?.split("/")?.[1];
    const category = getCategoryListingData(categoryId);
    setCategoryData(category);
    setBreadCrums(crumbs);
  }, [reducerData, productId]);

  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <Box sx={{ padding: isMobile ? "1rem" : "0rem" }}>
        <Box
          sx={{
            marginTop: "1rem",
            paddingInline: isNotDesktop ? "0rem" : "8rem",
          }}
        >
          <CustomBreadcrumbsNavigation breadCrums={breadCrums} />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0.2rem",
            paddingInline: isNotDesktop ? "0rem" : "8rem",
          }}
        >
          <Grid item md={12} lg={6}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ImageGallery
                items={productData?.images || images}
                showPlayButton={false}
                showFullscreenButton={false}
                additionalClass="custom-gallery" // Apply the custom class
              />
            </Box>
          </Grid>
          <Grid item md={12} lg={6}>
            <Box>
              <Box sx={productStyles.contentSide}>
                <Typography sx={productStyles.productName}>
                  {productData?.productName}
                  <span style={productStyles.productDescriptionSpan}>
                    {productData?.description?.split(".")?.[0]}
                  </span>
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Typography sx={productStyles.productPrice}>
                    ₹{productData?.price}
                  </Typography>
                  {productData?.discountPrice !== null && (
                    <Typography sx={productStyles.discountPrice}>
                      ₹{productData?.discountPrice}
                    </Typography>
                  )}
                </Box>
                <Divider sx={productStyles.divider} />
                <Box sx={{ display: "flex" }}>
                  <Typography sx={productStyles.contentLabel}>
                    Category:
                  </Typography>
                  <Typography
                    sx={{
                      ...productStyles.contentValue,
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    {productData?.category}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={productStyles.contentLabel}>
                    Availability :
                  </Typography>
                  <Typography
                    sx={{
                      ...productStyles.contentValue,
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    In stock
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={productStyles.contentLabel}>
                    Vendor :
                  </Typography>
                  <Typography
                    sx={{
                      ...productStyles.contentValue,
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    Best Karupatti Makers
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={productStyles.contentLabel}>
                    No of Ratings :
                  </Typography>
                  <Typography
                    sx={{
                      ...productStyles.contentValue,
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    {productData?.noOfRatings}
                    {productData?.showRating && (
                      <Box>
                        <Chip
                          icon={<StarIcon fontSize="0.5rem" />}
                          label={productData?.rating}
                          variant="outlined"
                          color="success"
                          sx={{ maxHeight: "20px" }}
                        />
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={productStyles.divider} />
              <Box sx={productStyles.iconButtonContainer}>
                <IconButton
                  sx={productStyles.iconButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setFavourites(productId));
                  }}
                >
                  {isFavourite ? (
                    <FavoriteIcon
                      sx={{
                        color: "#D15151",
                        verticalAlign: "middle",
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      sx={{
                        color: "#6C7882",
                        verticalAlign: "middle",
                      }}
                    />
                  )}
                </IconButton>
                {isCart ? (
                  <Button
                    variant="contained"
                    sx={productStyles.button}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setSelectedPage("Cart"));
                      router("/cart");
                    }}
                  >
                    View Cart
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={productStyles.button}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setCart(productId));
                    }}
                  >
                    Add to cart
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            margin: "1rem 0rem",
            paddingInline: isNotDesktop ? "0rem" : "8rem",
          }}
        >
          <BasicTabs
            isMobile={isMobile}
            isNotDesktop={isNotDesktop}
            label="Description"
            description={productData?.description}
          />
          <BasicTabs
            isMobile={isMobile}
            isNotDesktop={isNotDesktop}
            label="Related Products"
            description={categoryData}
            isArray
          />
        </Box>
      </Box>
    </AppLayout>
  );
};
