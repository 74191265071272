import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../features/image/logo.png";
import { setSelectedPage } from "../../reducers/favouritesSlice";
import { pages } from "../../utils/helper";
import { NavbarStyles } from "./StylesNavBar";

const MobileNavbar = () => {
  const router = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedPage = useSelector((state) => state.favourites.selectedPage);

  const handleDrawerToggle = (event) => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={NavbarStyles.mobileRoot}>
      <Box sx={NavbarStyles.mobileAlign}>
        <Box onClick={() => router("/")}>
          <img src={logo} alt={"Logo"} height={30} />
        </Box>
        <IconButton onClick={handleDrawerToggle} sx={{ padding: 0 }}>
          {!drawerOpen ? <MenuIcon /> : <CloseIcon />}
        </IconButton>
        <Popover
          open={drawerOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ marginTop: "3rem" }}
        >
          <Box sx={NavbarStyles.mobileMenus}>
            {pages.map((page, index) => (
              <Box
                key={index}
                sx={{
                  marginInline: "1rem",
                  color: page.name === selectedPage ? "#2C9763" : "#000000",
                  fontWeight: page.name === selectedPage ? 700 : 500,
                  cursor: "pointer",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                }}
                onClick={() => {
                  dispatch(setSelectedPage(page.name));
                  router(`/${page.route}`);
                  handleClose();
                }}
              >
                {page.name}
              </Box>
            ))}
            <Box
              key={pages.length + 1}
              sx={{
                marginInline: "1rem",
                color: selectedPage === "Favourites" ? "#2C9763" : "#000000",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: selectedPage === "Favourites" ? 700 : 500,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setSelectedPage("Favourites"));
                router(`/favourites`);
                handleClose();
              }}
            >
              Favourites
            </Box>
            <Box
              key={pages.length + 2}
              sx={{
                marginInline: "1rem",
                color: selectedPage === "Cart" ? "#2C9763" : "#000000",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: selectedPage === "Cart" ? 700 : 500,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setSelectedPage("Cart"));
                router(`/cart`);
                handleClose();
              }}
            >
              View Cart
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default MobileNavbar;
