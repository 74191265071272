import { Box } from '@mui/material';
import React from 'react';
import Navbar from '../../Navbar/Navbar';
import { FooterComponent } from '../FooterComponent';

 export const AppLayout = (props) => {
    const { children,isNotDesktop } = props
    return (
        <Box >
            <Navbar />
            <Box sx={{ paddingTop: isNotDesktop ? "0px" : "72px" }}>
                {children}
            </Box>
            <FooterComponent />
        </Box>
    );
}

