import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

//healthbenefits
import healthbenefitsArrow from "../features/image/healthbenefitsArrow.png";
import healthbenefitsHeart from "../features/image/healthbenefitsHeart.png";
import healthbenefitsLeaves from "../features/image/healthbenefitsLeaves.png";
import healthbenefitsMinerals from "../features/image/healthbenefitsMinerals.png";

//karupatti
import karupatti01 from "../features/image/karupatti01.png";
import karupatti02 from "../features/image/karupatti02.png";
import karupatti03 from "../features/image/karupatti03.png";
import karupatti04 from "../features/image/karupatti04.png";
import karupattiBg01 from "../features/image/karupattiBg01.jpg";

//Category
import categoryhealthmix from "../features/image/murungaicon.png";
import categorykarupatti from "../features/image/karupatticon.png";
import categoryoil from "../features/image/oilicon.png";
import categoryJaggery from "../features/image/jaggeryicon.png";

//Oil
import coconutoil from "../features/image/oil01.png";
import gingelyoil from "../features/image/oil02.png";
import groundnutoil from "../features/image/oil03.png";
import coconutoilBg from "../features/image/oilBg01.jpg";
import gingelyoilBg from "../features/image/oilBg02.png";

//Icons
import iconJaggery from "../features/assets/jaggeryicon.png";
import iconKarupatti from "../features/assets/karupatticon.png";
import iconMurunga from "../features/assets/murungaicon.png";
import iconOil from "../features/assets/oilicon.png";

export const pages = [
  {
    name: "Home",
    route: "",
  },
  {
    name: "Karupatti",
    route: "category/karupatti",
  },
  {
    name: "Jaggery",
    route: "category/jaggery",
  },
  {
    name: "Cold pressed oil",
    route: "category/cold-pressed-oil",
  },
  {
    name: "Murunga powder",
    route: "category/murunga-powder",
  },
  {
    name: "About Us",
    route: "aboutus",
  },
  {
    name: "Contact Us",
    route: "contactus",
  },
];

export const categoriesDescription = [
  {
    name: "karupatti",
    description:
      "Made from freshly concentrated product of cane sugar and date palm tree, Palm jaggery is a treat to taste buds. The process of making palm jaggery does not involves use of any chemical agents, hence all the natural minerals are retained as no preservatives are added. ",
  },
  {
    name: "jaggery",
    description:
      "Jaggery is a traditional non-centrifugal cane sugar consumed in the Indian subcontinent, Southeast Asia, Central America, Brazil and Africa. It is a concentrated product of cane juice and often date or palm sap without separation of the molasses and crystals, and can vary from golden brown to dark brown in colour",
  },
  {
    name: "cold-pressed-oil",
    description:
      "Cold-pressed oils are fatty oils extracted from seeds, nuts or fatty fruits such as olives (scientifically, olives are fruit). These oils are usually used in cooking. Essential oils are highly concentrated, intensely fragrant and extracted from aromatic plants such as lavender. ",
  },
  {
    name: "murunga-powder",
    description:
      "Murunga powder is a powder made from more than 27 Natural Organic Grains and Millets.Millets are full of soluble fiber, which trap fat in your gut and can lower the cholesterol level in your blood. That can help reduce your chances of atherosclerosis, or heart disease. Millets are good sources of magnesium, too, which may prevent heart failure.",
  },
];

export const cardData = [
  {
    showRating: true,
    rating: "4.3",
    noOfRatings: "100 ratings",
    productName: "Karupatti (Palm Jagerry) - 1kg",
    price: "420",
    discountPrice: "620",
    offer: "",
    healthbenefits: "eco-friendly",
    category: "karupatti",
    id: "1",
    cardImage: karupatti01,
    images: [
      {
        original: karupattiBg01,
        thumbnail: karupatti01,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
  {
    showRating: true,
    rating: "4.3",
    noOfRatings: "100 ratings",
    productName: "Karupatti (Palm Jagerry) - 500 gms",
    price: "220",
    discountPrice: "320",
    offer: "",
    healthbenefits: "eco-friendly",
    category: "karupatti",
    id: "2",
    cardImage: karupatti02,
    images: [
      {
        original: karupatti02,
        thumbnail: karupatti02,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
  {
    showRating: true,
    rating: "4.6",
    noOfRatings: "220 ratings",
    productName: "Palm Crystal or Candy (Panangkalkandu) - 250 Gms.",
    price: "150",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "karupatti",
    id: "3",
    cardImage: karupatti03,
    images: [
      {
        original: karupatti03,
        thumbnail: karupatti03,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Palm Jaggery powder - 250 Gms.",
    price: "140",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "karupatti",
    id: "4",
    cardImage: karupatti04,
    images: [
      {
        original: karupatti04,
        thumbnail: karupatti04,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Sesame Oil - 1 Ltr",
    price: "340",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "5",
    cardImage: gingelyoil,
    images: [
      {
        original: gingelyoilBg,
        thumbnail: gingelyoil,
      },
    ],
    description: `Cold-pressed oil is a type of oil that is extracted from seeds, nuts, or fruits using a mechanical press without the use of heat or chemicals. The process of cold pressing involves crushing the source material (such as olives, coconuts, or seeds) at a low temperature to extract the oil.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Coconut Oil - 1 Ltr",
    price: "320",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "6",
    cardImage: coconutoil,
    images: [
      {
        original: coconutoilBg,
        thumbnail: coconutoil,
      },
    ],
    description: `Cold-pressed oil is a type of oil that is extracted from seeds, nuts, or fruits using a mechanical press without the use of heat or chemicals. The process of cold pressing involves crushing the source material (such as olives, coconuts, or seeds) at a low temperature to extract the oil.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Groundnut Oil - 1 Ltr",
    price: "240",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "7",
    cardImage: groundnutoil,
    images: [
      {
        original: groundnutoil,
        thumbnail: groundnutoil,
      },
    ],
    description: `Cold-pressed oil is a type of oil that is extracted from seeds, nuts, or fruits using a mechanical press without the use of heat or chemicals. The process of cold pressing involves crushing the source material (such as olives, coconuts, or seeds) at a low temperature to extract the oil.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Sesame Oil - 500 ml",
    price: "175",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "8",
    cardImage: gingelyoil,
    images: [
      {
        original: gingelyoilBg,
        thumbnail: gingelyoil,
      },
    ],
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Cocunut Oil - 500 ml",
    price: "160",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "9",
    cardImage: coconutoil,
    images: [
      {
        original: coconutoilBg,
        thumbnail: coconutoil,
      },
    ],
    description: `Cold-pressed oil is a type of oil that is extracted from seeds, nuts, or fruits using a mechanical press without the use of heat or chemicals. The process of cold pressing involves crushing the source material (such as olives, coconuts, or seeds) at a low temperature to extract the oil.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Cold Pressed Groundnut Oil - 500ml",
    price: "130",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "oil",
    id: "10",
    cardImage: groundnutoil,
    images: [
      {
        original: groundnutoil,
        thumbnail: groundnutoil,
      },
    ],
    description: `Cold-pressed oil is a type of oil that is extracted from seeds, nuts, or fruits using a mechanical press without the use of heat or chemicals. The process of cold pressing involves crushing the source material (such as olives, coconuts, or seeds) at a low temperature to extract the oil.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Palm Jaggery powder - 250 Gms.",
    price: "140",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "jaggery",
    id: "11",
    cardImage: iconJaggery,
    images: [
      {
        original: iconJaggery,
        thumbnail: iconJaggery,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
  {
    showRating: true,
    rating: "4.9",
    noOfRatings: "1400 ratings",
    productName: "Palm Jaggery powder - 250 Gms.",
    price: "140",
    discountPrice: null,
    offer: "",
    healthbenefits: "eco-friendly",
    category: "murunga-powder",
    id: "12",
    cardImage: iconMurunga,
    images: [
      {
        original: iconMurunga,
        thumbnail: iconMurunga,
      },
    ],
    description: `Karupatti, also known as palm jaggery, is a traditional sweetener made from the sap of palm trees. It is a natural and unrefined product, unlike white sugar which undergoes extensive processing. Karupatti is dark brown in color and has a rich, caramel-like flavor with notes of molasses.`,
  },
];

export const categories = [
  {
    name: "Karupatti",
    price: "Starts from ₹140",
    image: categorykarupatti,
    icon: <ArrowCircleRightIcon sx={{ color: "#973F2C" }} />,
    homeIcon: iconKarupatti,
    borderColor: "#973F2C",
    background:
      "linear-gradient(90deg, rgba(151, 63, 44, 0.1) 0%, rgba(151, 63, 44, 0) 100%)",
    route: "category/karupatti",
    noOfItems: "(2 Items)",
    description:
      "Made from freshly concentrated product of cane sugar and date palm tree, Palm jaggery is a treat to taste buds. The process of making palm jaggery does not involves use of any chemical agents, hence all the natural minerals are retained as no preservatives are added. ",
  },
  {
    name: "Jaggery",
    price: "Starts from ₹99",
    image: categoryJaggery,
    icon: <ArrowCircleRightIcon sx={{ color: "#2C6397" }} />,
    homeIcon: iconJaggery,
    borderColor: "#2C6397",
    background:
      "linear-gradient(90deg, rgba(44, 99, 151, 0.1) 0%, rgba(44, 99, 151, 0) 100%)",
    route: "category/jaggery",
    noOfItems: "(2 Items)",
    description:
    "Jaggery is a traditional non-centrifugal cane sugar consumed in the Indian subcontinent, Southeast Asia, Central America, Brazil and Africa. It is a concentrated product of cane juice and often date or palm sap without separation of the molasses and crystals, and can vary from golden brown to dark brown in colour",
  },
  {
    name: "Cold pressed oil",
    price: "Starts from ₹129",
    image: categoryoil,
    icon: <ArrowCircleRightIcon sx={{ color: "#8E972C" }} />,
    homeIcon: iconOil,
    borderColor: "#8E972C",
    background: `linear-gradient(90deg, rgba(142, 151, 44, 0.1) 0%, rgba(142, 151, 44, 0) 100%)`,
    route: "category/cold-pressed-oil",
    noOfItems: "(2 Items)",
    description:
      "Cold-pressed oils are fatty oils extracted from seeds, nuts or fatty fruits such as olives (scientifically, olives are fruit). These oils are usually used in cooking. Essential oils are highly concentrated, intensely fragrant and extracted from aromatic plants such as lavender. ",
  },
  {
    name: "Murunga powder",
    price: "Starts from ₹135",
    image: categoryhealthmix,
    icon: <ArrowCircleRightIcon sx={{ color: "#2C9763" }} />,
    homeIcon: iconMurunga,
    borderColor: "#2C9763",
    background:
      "linear-gradient(90deg, rgba(44, 151, 99, 0.1) 0%, rgba(44, 151, 99, 0) 100%)",
    route: "category/health-mix",
    noOfItems: "(2 Items)",
    description:
    "Murunga powder is a powder made from more than 27 Natural Organic Grains and Millets.Millets are full of soluble fiber, which trap fat in your gut and can lower the cholesterol level in your blood. That can help reduce your chances of atherosclerosis, or heart disease.",
  },
];

export const flagShipProducts = [
  {
    name: "Karupatti",
    price: "Starts from ₹140",
    image: categorykarupatti,
    icon: <ArrowCircleRightIcon sx={{ color: "#973F2C" }} />,
    borderColor: "#973F2C",
    background:
      "linear-gradient(90deg, rgba(151, 63, 44, 0.1) 0%, rgba(151, 63, 44, 0) 100%)",
    route: "category/karupatti",
  },
  {
    name: "Jaggery",
    price: "Starts from ₹99",
    image: categoryJaggery,
    icon: <ArrowCircleRightIcon sx={{ color: "#2C6397" }} />,
    borderColor: "#2C6397",
    background:
      "linear-gradient(90deg, rgba(44, 99, 151, 0.1) 0%, rgba(44, 99, 151, 0) 100%)",
    route: "category/rice",
  },
  {
    name: "Cold pressed oil",
    price: "Starts from ₹129",
    image: categoryoil,
    icon: <ArrowCircleRightIcon sx={{ color: "#8E972C" }} />,
    borderColor: "#8E972C",
    background: `linear-gradient(90deg, rgba(142, 151, 44, 0.1) 0%, rgba(142, 151, 44, 0) 100%)`,
    route: "category/cold-pressed-oil",
  },
  {
    name: "Murunga Powder",
    price: "Starts from ₹135",
    image: categoryhealthmix,
    icon: <ArrowCircleRightIcon sx={{ color: "#2C9763" }} />,
    borderColor: "#2C9763",
    background:
      "linear-gradient(90deg, rgba(44, 151, 99, 0.1) 0%, rgba(44, 151, 99, 0) 100%)",
    route: "category/health-mix",
  },
];

export const healthCategories = [
  {
    name: "Low glycemic",
    price: "Healthy Substitue",
    image: healthbenefitsArrow,
    icon: <></>,
    borderColor: "#CED5DB",
    background: "",
    route: "category/karupatti",
  },
  {
    name: "High load of minerals",
    price: "Fe, Ca, Mg, B-complex, B12",
    image: healthbenefitsMinerals,
    icon: <></>,
    borderColor: "#CED5DB",
    background: "",
    route: "category/karupatti",
  },
  {
    name: "Eco-friendly",
    price: "sustainable source of sweetner",
    image: healthbenefitsLeaves,
    icon: <></>,
    borderColor: "#CED5DB",
    background: "",
    route: "category/karupatti",
  },
  {
    name: "Medicinal properties",
    price: "Healthy substitute",
    image: healthbenefitsHeart,
    icon: <></>,
    borderColor: "#CED5DB",
    background: "",
    route: "category/karupatti",
  },
];

export const getAllcards = (ids) => {
  const temp = [];
  ids &&
    ids?.forEach((id) => {
      const card = cardData.find((item) => item.id === id);
      temp.push(card);
    });

  return temp;
};

export const getCategoryListingData = (category) => {
  let productData;
  switch (category) {
    case "karupatti":
      productData = cardData.filter((item) => item.category === "karupatti");
      break;
    case "cold-pressed-oil":
      productData = cardData.filter((item) => item.category === "oil");
      break;
    case "jaggery":
      productData = cardData.filter((item) => item.category === "jaggery");
      break;
    case "murunga-powder":
      productData = cardData.filter(
        (item) => item.category === "murunga-powder"
      );
      break;
    default:
      productData = [];
  }

  return productData;
};

export const getDiscription = (id) => {
  return categoriesDescription.find((item) => item.name === id)?.description;
};

export const getProductById = (id) => {
  return cardData.find((item) => item.id === id);
};

export const toPascalCase = (str) => {
  if (!str) return "";

  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
