export const pageNotFoundStyle = {
  rootSx: {},
  headerTextSx: {
    fontWeight: 400,
    marginTop: "5rem",
    paddingBottom: "1.2rem",
  },
  subTextSx: {
    fontWeight: 500,
  },
};
