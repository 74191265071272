export const carousalStyles = {
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  contentText: {
    fontWeight: 600,
    display: "flex",
    flexWrap: "wrap",
    color: "#fff",
  },
};
