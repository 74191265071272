export const shippingData = [
  {
    header: " ",
    data: `The estimated delivery time for any given shipment will vary according to the method of shipping you choose and/or by your location. Please note that although we make an effort to meet the estimated delivery times, because we use a third party shipping service we are not able to guarantee a specific delivery date or time. If you have time sensitive materials, please make sure you allow sufficient time to receive your order.`,
  },
  {
    header: " ",
    data: `We reserve the right to change the site initially allocated to the production of your order. (For example, equipment failure, natural disaster or order volumes may impact our production capacity requirements necessitating a change in site). Should this happen, you may be informed by email and it may impact your estimated shipping time, etc. You can check our shipping maps for the estimated transit time from the new production site.`,
  },
  {
    header: " ",
    data: `Our shipping process cannot generally accommodate the aggregation of multiple orders for shipping purposes; each discrete product ordered will be shipped separately, will require a separate shipping charge, will have its own estimated shipping time, and may arrive on a different date to other products you have ordered. If you are ordering multiple products, it is possible that these may also require different production sites and the estimated shipping time may vary for each separate product. If the receipt of multiple products is time sensitive please plan accordingly as we cannot guaranty that multiple product orders will be received on the same delivery date.`,
  },
];
