import {
  Box,
  keyframes,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StHeadingTypography as OriginalHeadingTypography,
  StTypography as OriginalTypography,
  StButton as OriginalButton,
} from "../../Componentstyles";
import { carousalStyles } from "./CarousalStyles";
import { useNavigate } from "react-router-dom";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StHeadingTypography = styled(OriginalHeadingTypography)`
  animation: ${fadeIn} 2s ease-out;
`;

const StTypography = styled(OriginalTypography)`
  animation: ${fadeIn} 2s ease-out;
`;

const StButton = styled(OriginalButton)`
  animation: ${fadeIn} 2s ease-out;
`;

const CarouselItem = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useNavigate();
  const handleRoute = () => {
    router(`/category/karupatti`);
  };

  return (
    <Box sx={{ height: "600px" }}>
      <Box
        sx={{
          position: "relative",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <img
          src={isMobile ? props.item.mblImage : props.item.image}
          alt="karupatti"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <Box
          sx={{
            ...carousalStyles.image,
            left: isMobile ? "1%" : "60%",
            top: isMobile ? "10%" : "25%",
          }}
        >
          <StHeadingTypography
            sx={{
              ...carousalStyles.contentText,
              fontSize: isMobile ? "20px" : "38px",
              lineHeight: isMobile ? "14px" : "40px",
              fontWeight: 600,
              width: isMobile ? "100%" : "40%",
              justifyContent: isMobile ? "center" : "left",
            }}
          >
            {props.item.name}
          </StHeadingTypography>
          <StTypography
            sx={{
              ...carousalStyles.contentText,
              color: "#fff",
              fontSize: isMobile ? "16px" : "1rem",
              width: isMobile ? "100%" : "40%",
              fontWeight: 400,
              justifyContent: isMobile ? "center" : "left",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            {props.item.description}
          </StTypography>
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "left",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <StButton
              sx={{
                width: isMobile ? "100px" : "200px",
                height: isMobile ? "30px" : "60px",
              }}
              onClick={() => handleRoute()}
            >
              Shop Now
            </StButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CarouselItem;
