import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import CardList from "../../components/Card/CardList";
import { CardItem } from "../../components";
import { Grid } from "@mui/material";
import { StTypography } from "../../Componentstyles";
import { productStyles } from "./productStyles";

function CustomTabPanel(props) {
  const { children, value, index, isMobile, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: isMobile ? "24px 0px" : 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const { description, label, isArray, isMobile, isNotDesktop } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#2C9763 !important",
            },
          }}
        >
          <Tab sx={productStyles.productTab} label={label} {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} isMobile={isMobile}>
        {isArray ? (
          <Grid container spacing={isMobile ? 2 : 3}>
            {description?.length > 0 &&
              description?.map((card, index) => {
                if (isMobile ? index < 2 : index < 4)
                  return (
                    <Grid item key={index} xs={6} md={3}>
                      <CardItem isMobile={isMobile} {...card} key={index} />
                    </Grid>
                  );
              })}
          </Grid>
        ) : (
          <StTypography isNotDesktop={isNotDesktop}>{description}</StTypography>
        )}
      </CustomTabPanel>
    </Box>
  );
}
