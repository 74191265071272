import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { AppLayout } from "../../components";
import { aboutUsStyles } from "./AboutUsStyles";
import aboutUs1 from "../../image/aboutusImage.jpg";
import aboutUs2 from "../../../features/assets/contactus1.webp";
import { StHeadingTypography, StTypography } from "../../Componentstyles";

export const AboutUs = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <Box>
        <StHeadingTypography
          sx={{ py: isMobile ? "2rem" : "3rem", textAlign: "center"}}
        >
          About us
        </StHeadingTypography>
        <Box sx={{...aboutUsStyles.aboutUsContainer, paddingBottom: isMobile ? "1rem" : "5rem"}}>
          <Grid container spacing={isMobile ? 2 : 6} alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={aboutUs1} alt="about-us" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" sx={{ pb: "1rem" }}>
                Best Karupatti Brands
              </Typography>
              <StTypography sx={{ pb: "1rem" }}>
                Best karupatti makers was founded in the year 2015 by a group of
                visionaries with an idea to bring natural,healthy and
                traditional food products to the forefront.we at Bkm believe in
                the best.our flagship product vertical is palm jaggery popularly
                known as karupatti and we are proud to offer our services to
                over a few thousand customers across the globe.
              </StTypography>

              <StTypography>
                As the awareness towards natural foods is increasing, the demand
                on karupatti is only growing by the day and so we have a need to
                adapt to our customer needs in form and presentation which we
                are happy to do since we believe in ‘customer is king’. BKM
                customer retention rate has been almost 100% and that is
                testimony to our quality and service.
              </StTypography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={isMobile ? 2 : 6}
            alignItems="center"
            direction={"row-reverse"}
          >
            <Grid item xs={12} md={6}>
              <img src={aboutUs2} alt="about-us" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <StTypography sx={{ pb: "1rem" }}>
                About: This chocolate, caramel tasting jaggery ( in naturally
                crystallized form ) with strong aroma has high mineral content,
                low glycemic index and medicinal properties. This has increasing
                demand globally – due to its health benefits and people moving
                back to their roots for healthy foods. When used in place of
                white sugar, it enhances the taste of coffee. Here too, reliable
                pure source in the open market is hard to find.
              </StTypography>

              <StTypography>
                High consumption of white sugar/ added sugar causes sudden
                rise/spike in blood sugar – this leads to many life-style
                ailments like Type-II diabetes etc. palm sugar being low
                glycemic (41) is absorbed slowly and sugar is less absorbed
                during digestion. It contains compounds that inhibit absorption
                of sugar in the digestion process.
              </StTypography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </AppLayout>
  );
};
