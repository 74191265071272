export const contactUsStyles = {
  root: {
    padding: "4rem 0rem",
  },
  header: { textAlign: "center", fontSize: "2rem", pb: "1rem" },
  contactUsContainer: {
    width: "80%",
    margin: "auto",
    paddingBottom: "5rem",
  },
  contactUsCard: {
    border: "1px solid #2C9763",
    borderRadius: "10px",
    padding: "1rem",
    backgroundColor: "white",
  },
  cardHeader: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#2C9763",
    textAlign: "center",
  },
  subHeader: {
    fontSize: "12px",
    lineHeight: 2,
    fontFamily: "Poppins",
  },
  addressContent: {
    display: "flex",
    gap: "0.4rem",
    mt: "0.6rem",
    alignItems: "center",
  },
  content: {
    width: "60%",
    margin: "auto",
  },
  formContainer: {
    padding: "1rem",
    border: "1px solid #2C9763",
    borderRadius: "10px",
    backgroundColor: "white",
    marginTop: "2rem",
  },
  formContent: {
    padding: "1rem 0rem",
  },
  submitButton: {
    backgroundColor: "#2C9763",
    color: "white",
    padding: "0.4rem 1rem",
    textTransform:"none",
  },
};
