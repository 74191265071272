import { Box, Grid } from "@mui/material";
import CardItem from ".";
import { cardStyles } from "./CardStyles";

const CardList = (props) => {
  const { isMobile, cardData } = props;
  return (
    <>
      {/* <Grid container spacing={5}>
        {cardData?.length > 0 &&
          cardData?.map((card, index) => (
            <Grid item xs={12} md={3}>
              <CardItem {...card} key={index} />
            </Grid>
          ))}
      </Grid> */}
    </>
  );
};

export default CardList;
