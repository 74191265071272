export const intellectualPropertyData = [
    {
        header:"",
        data:`All content of the Website, including without limitation, HTML text, graphics, other files, code, software layout, designs, forms, templates, artwork, photographs, images, document layouts, text, fonts, music, software tools, and other information, either alone and/or as compilation thereof (collectively, the ‘Site Content”), constitutes the copyrighted property of karupatti.com or of those parties from whom karupatti.com has licensed such property, and is protected by United States, international and other laws. karupatti.com reserves all rights in and to this Website and the Site Content worldwide. Your use of this Website and/or any Site Content does not convey to you or any other person an interest of any kind to the Site Content. karupatti.com reserves the right to add to, delete from, or modify any part of the Site Content at any time without prior notice.\n\n`
    },
    {
        header:"",
        data:`Trademarks. ” karupatti.com,” and their respective designs and/or logos, are either trademarks or registered trademarks of karupatti.com and may not be copied, imitated or used, in whole or in part, without the prior written permission of karupatti.com. In addition, all page headers, custom graphics, button icons, and scripts are trademarks and/or trade dress of karupatti.com, and may not be copied, imitated, or used, in whole or in part, without the prior written permission of karupatti.com. All other trademarks, registered trademarks, product names and company names or logos mentioned on the Website are the property of their respective owners.\n\n`
    },
    {
        header:"",
        data:`Restrictions on Use. Except as expressly stated in the Site Terms, none of the materials and Intellectual Property described herein may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording or otherwise, without the prior written permission of karupatti.com, or the respective intellectual property owner. Any use of Site Content — including any commercial use, reproduction for purposes other than as permitted under these Site Terms, modification, distribution, republication, display, or performance — without the prior written permission of karupatti.com is strictly prohibited.\n\n`
    },
    {
        header:"",
        data:`Software. Any software, including any files, images incorporated in or generated by the software, and data accompanying the software (collectively “Software”), that may be made available on or through this Website is licensed to you by karupatti.com on a non- exclusive and limited basis solely for the purpose of creating and ordering print products or services from karupatti.com. karupatti.com does and shall retain full and complete title and all intellectual property rights to such Software. You shall not copy, distribute, sell, modify, decompile, reverse engineer, disassemble or create derivative works from any such Software.\n\n`
    }

]