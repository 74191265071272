import { Box, Card, CardContent, useTheme } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { benefitsStyles } from "./BenefitsStyles";

export const BenefitsCard = (props) => {
  const { item, index, isMobile } = props;
  const theme = useTheme();
  const ResponsiveTypography = styled("div")(({ theme }) => ({
    fontSize: "14px",
    [theme.breakpoints?.down("sm")]: {
      fontSize: "12px",
    },
  }));
  return (
    <Card
      key={index}
      variant="outlined"
      sx={isMobile ? benefitsStyles.rootMobile : benefitsStyles.root}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: isMobile ? "4px" : "8px",
          justifyContent: isMobile ? "center" : "flex-start",
        }}
      >
        <Box sx={{ paddingLeft: isMobile ? "4px" : "8px" }}>
          <img src={item.image} alt={item.name} width={isMobile ? 20 : 35} />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <ResponsiveTypography level="title-md">
            {item.name}
          </ResponsiveTypography>
          <ResponsiveTypography sx={{ fontSize: "13px" }}>
            {item.price}
          </ResponsiveTypography>
        </Box>
      </Box>
    </Card>
  );
};
