export const termsData = [
  {
    header: "",
    data: `Please read these terms & conditions carefully. When you access, browse and/or use this web-site (including the ordering of products through this website) you are agreeing to be bound by all of the  terms & conditions stated below and all applicable laws.\n\n  If you do not agree to be bound by this web-site’s terms & conditions and applicable law, or if you do not have the authority to agree to or accept these terms and conditions, you may not use this website.\n\n Note: As more fully explained below karupatti.com reserves the right to change, modify or amend these terms and conditions without prior notice. Your continued use of this site is and shall be deemed to be your agreement to be bound by any such modifications, alterations or updates.`,
  },
  {
    header: "Related policies and terms",
    data: `There are certain additional policies, terms and conditions that apply to the use of specific portions of this Web Site, and to the purchase of products from karupatti.com. These additional policies, terms and conditions are included as and shall be deemed to be part of these Terms and Conditions (collectively all such terms and conditions are referred to as the “Site Terms”). Please refer to and review all additional specific terms and conditions, including the following:\n
            • Shipping Policy
            • Reprint / Refund Policy
            • Privacy Statement\n
            The Site Terms apply exclusively to your access and/or use of this Web Site and do not alter in any way the terms or conditions of any other written agreement you may have with karupatti.com.\n
            karupatti.com values its customers’ privacy. Please make sure you review the Privacy Statement section of this Website so that you may understand how karupatti.com collects and uses information.`,
  },
  {
    header: "Site use",
    data: `You are granted permission to access and use this Site and its Content for the sole purpose of preparing, evaluating, and ordering products or services solely through karupatti.com (referred to herein as “Products”). No other download, retention, use, publication, or distribution of any portion of the Content is authorized or permitted. Obtaining Products from karupatti.com does not entitle you to use any portion of Content apart from the finished Products as they are supplied by karupatti.com.\n
           You agree to use this Site in a responsible manner that is in full compliance with these Terms of Use and with your local laws and regulations, including export and import regulations. Without limitation, no portion of Content may be utilized as a trademark or service mark, for any pornographic use, for any unlawful purpose or use, to defame any person, to violate any person’s right of privacy or publicity, to infringe upon any copyright, trade name, trademark, service mark or other intellectual property right of any person or entity. You agree that you will not use the Site to produce Products that are offensive, unlawful, harassing, libelous, threatening, harmful, obscene, malicious or otherwise objectionable. karupatti.com may terminate its service to customers found to be using karupatti.com to engage in undesirable activities.\n
           You agree that you are responsible for protecting your password and controlling access to your registered account. You agree that you will be responsible for all orders placed or other actions that are taken through your registered account`,
  },
  {
    header: "Transfer of Title",
    data: `The customer agrees that the shipping terms for all Products are FOB shipping point and ownership transfers to the customer upon shipment. This means that the risk of loss and title for such items pass to you upon our delivery to the carrier.`,
  },
  {
    header: "Indemnification",
    data: `You agree that you shall indemnify and defend karupatti.com and all parties from whom karupatti.com has licensed portions of Content, and their directors, officers, and employees, against all claims, liability, damages, costs and expenses, including reasonable legal fees and expenses arising out of or related to\n
    (i) your breach of these Terms of Use or
    (ii) any suit, claim, or demand arising from or relating to any text, photograph, image, graphic or other material you incorporated into Products that was not part of the standard Site Content.`,
  },
  {
    header: "Disclaimer of Warranty",
    data: `The site and its content are provided “as is” without warranty of any kind, either expressed or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement. You acknowledge that operation of the site may not be uninterrupted or error free. References and links to products or services of independent companies may appear on the site. These references and links are provided “as is” without warranty of any kind, either expressed or implied.`,
  },
  {
    header: "Limitation of Liability",
    data: `In no event shall karupatti.com or its licensors, suppliers, or vendors, their officers, directors, employees, or agents be liable for any special, incidental, indirect, or consequential damages of any kind, or for any damages whatsoever resulting from loss of use, data, or profits, whether or not karupatti.com has been advised of the possibility of damage, arising out of or in connection with the use or performance of the site or of failure to provide products or services that you order from karupatti.com or its affiliates, including without limitation, damages arising from mistake, omission, virus, delay, or interruption of service. In no event shall karupatti.com be liable or responsible for any damages or consequences arising from or related to your inappropriate or unauthorized use of this site or its content. In no event shall karupatti.com be responsible for any delays / damages occurred during transit of shipments.`,
  },
  {
    header:"Site Feedback",
    data:`Any comments, suggestions, proposals or other feedback (collectively “Feedback Information”) provided to karupatti.com in connection with the operation or content of this Site shall be provided by the submitter and received by karupatti.com on a non-confidential basis. All such comments, suggestions, and other information shall become the exclusive property of karupatti.com. By submitting any such information to karupatti.com, you agree that you are transferring and assigning, at no charge, all of your right, title and interest in the information, including all copyrights and other intellectual property rights. You agree that karupatti.com shall be free to use such information on an unrestricted basis.`
  }
];
