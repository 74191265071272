import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favouriteIds: [],
  cartIds: [],
  selectedPage: "Home",
};

export const favouritesSlice = createSlice({
  name: "favouritesSlice",
  initialState,
  reducers: {
    setFavourites: (state, action) => {
      return {
        ...state,
        favouriteIds: state.favouriteIds.includes(action.payload)
          ? state.favouriteIds.filter((id) => id !== action.payload)
          : [...state.favouriteIds, action.payload],
      };
    },
    setCart: (state, action) => {
      return {
        ...state,
        cartIds:
          state.cartIds && state.cartIds.includes(action.payload)
            ? state?.cartIds?.filter((id) => id !== action.payload)
            : [...state.cartIds, action.payload],
      };
    },
    removeCart: (state, action) => {
      return {
        ...state,
        cartIds: state.cartIds.filter((id) => id !== action.payload),
      };
    },
    setSelectedPage: (state, action) => {
      return {
        ...state,
        selectedPage: action.payload,
      };
    },
  },
});

export const { setFavourites, setCart, setSelectedPage,removeCart } =
  favouritesSlice.actions;

export default favouritesSlice.reducer;
