import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  AboutUs,
  Cart,
  CategoryProducts,
  ContactUs,
  Disclaimer,
  Favourite,
  IntellectualProperty,
  LandingPage,
  PageNotFound,
  PrivacyPolicy,
  ProductDescriptionPage,
  ReturnCancel,
  Shipping,
  TermsConditions,
} from "../features";
import { Layout } from "../features/components";
import { routes } from "./routes";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: routes.intro,
        element: <LandingPage />,
        errorElement: <PageNotFound />,
      },
      {
        path: routes.category,
        element: <CategoryProducts />,
      },
      {
        path: routes.product,
        element: <ProductDescriptionPage />,
      },
      {
        path: routes.favourites,
        element: <Favourite />,
      },
      {
        path: routes.cart,
        element: <Cart />,
      },
      {
        path: routes.aboutUs,
        element: <AboutUs />,
      },
      {
        path: routes.contactUs,
        element: <ContactUs />,
      },
      {
        path: routes.termConditions,
        element: <TermsConditions />,
      },
      {
        path: routes.shipping,
        element: <Shipping />,
      },
      {
        path: routes.returnCancel,
        element: <ReturnCancel />,
      },
      {
        path: routes.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: routes.intellectualProperty,
        element: <IntellectualProperty />,
      },
      {
        path: routes.disclaimer,
        element: <Disclaimer />,
      },
    ],
    errorElement: <PageNotFound />,
  },
]);

const RouterApp = () => {
  return <RouterProvider router={router} />;
};
export default RouterApp;
