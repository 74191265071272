import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { StHeadingTypography } from "../../Componentstyles";

export const PolicyComponent = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const { data, header, noHeaderSpace } = props;
  return (
    <Box>
      <StHeadingTypography
        sx={{
          pt: "2rem",
          pb: noHeaderSpace ? "0rem" : isMobile ? "1rem" : "2rem",
          textAlign: "center",
          width: isMobile ? "85%" : "80%",
          margin: "auto",
        }}
        isNotDesktop={isNotDesktop}
      >
        {header}
      </StHeadingTypography>
      <Box sx={{ width: isMobile ? "85%" : "80%", mx: "auto", pb: "3rem" }}>
        {data?.map((item, index) => (
          <Box>
            {item.header && (
              <StHeadingTypography
                sx={{ pt: isMobile ? "1rem" : "2rem", fontSize: isMobile ? "1rem" : "1.5rem" }}
              >
                {item.header}
              </StHeadingTypography>
            )}
            <Typography
              sx={{ fontSize: isMobile ? "12px" : "1rem",fontFamily: "Poppins" }}
              component="div"
              dangerouslySetInnerHTML={{
                __html: item.data.replace(/\n/g, "<br />"),
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
