import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Grid, InputAdornment, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { AppLayout, CustomTextField } from "../../components";
import { StHeadingTypography } from "../../Componentstyles";
import { ContactUsCard } from "./ContactUsCard";
import { contactUsStyles } from "./ContactUsStyles";

export const ContactUs = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const contacts = [
    {
      header: "Best Karupatti Makers",
      addressLine1: "No.47/19, Chidambaram Street",
      addressLine2: "Bharathi Puram, Chrompet.",
      addressLine3: "Chennai – 600044",
      phone: "+91-94455 11283 /+91 72000 56569",
      email: "bkm@karupatti.com",
    },
    {
      header: "Tawakkal Trading LLC",
      addressLine1: "Warehouse no. 25 & 26, Al Habtoor Warehouse,",
      addressLine2: "Al Qusais Industrial Area 3,",
      addressLine3: "Dubai, UAE, PO Box no. 90024",
      phone: "+971 50 1475269 / Mr. Sabeer Ali",
      email: "sify158@hotmail.com",
    },
  ];

  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <Box sx={{...contactUsStyles.root,padding:isMobile?"1rem 0rem":"4rem 0rem"}}>
        <StHeadingTypography sx={contactUsStyles.header}>
          Contact us
        </StHeadingTypography>
        <Box sx={{...contactUsStyles.content, width:isMobile?"80%":"60%"}}>
          <Grid container spacing={4}>
            {contacts.map((contact) => (
              <Grid item xs={12} md={6}>
                <ContactUsCard {...contact} />
              </Grid>
            ))}
          </Grid>
          <Box sx={contactUsStyles.formContainer}>
            <Typography variant="h4" sx={{...contactUsStyles.cardHeader,fontSize:"1.5rem"}}>
              Get a Queue
            </Typography>
            <Box sx={contactUsStyles.formContent}>
              <CustomTextField
                sx={{ pb: "1.5rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon sx={{ color: "#2C9763" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter your name"
                label="Name"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                sx={{ pb: "1.5rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon sx={{ color: "#2C9763" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter your Phone Number"
                label="Phone Number"
                variant="outlined"
                fullWidth
              />{" "}
              <CustomTextField
                sx={{ pb: "1.5rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon sx={{ color: "#2C9763" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter your email"
                label="Email"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                sx={{ pb: "1.5rem" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DescriptionIcon sx={{ color: "#2C9763" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Subject"
                label="Subject"
                variant="outlined"
                fullWidth
              />
              <CustomTextField
                sx={{ pb: "1.5rem" }}
                placeholder="Your Message"
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
              />
              <Box textAlign={"center"}>
                <Button variant="contained" sx={contactUsStyles.submitButton}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppLayout>
  );
};
