export const ProductCardStyles = {
  root: {
    // maxWidth: `254px`,
    display: "flex",
    flexDirection: "column",
    gap: "0.2rem",
    borderRadius: "0px",
    border: `1px solid #f2f2f2`,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1), 
              0px 1px 4px rgba(0, 0, 0, 0.12), 
              0px 2px 4px rgba(0, 0, 0, 0.14)`,
    "&:hover": {
      transform: "translateY(-2px)",
      transition: "transform 0.3s ease-in-out",
      boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.2), 
              0px 4px 8px rgba(0, 0, 0, 0.15), 
              0px 5px 10px rgba(0, 0, 0, 0.1)`,
    },
    cursor: "pointer",
  },
  cardContentRoot: {
    margin: 0,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
  },
  cardContentRootMobile: {
    margin: 0,
    padding: "0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
    paddingBottom: "0.8rem !important",
  },
  productName: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "12px",
    color: "#000",
    minHeight: "40px",
    maxWidth: "204px",
    textAlign: "center",
    display:"flex",
    alignItems:"center",
  },
  productPrice: {
    fontSize: "20px",
    color: "#30363C",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "24px",
  },
  priceAlign: { display: "flex", gap: "5px" },
  imageSection: {
    backgroundColor: "#f2f2f2",
    height: "230px",
  },
  imageSectionMobile: {
    backgroundColor: "#f2f2f2",
    height: "180px",
  },
  imageAlign: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0.5rem",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    padding:"12px 0px"
  },
  productIcons: {
    color: "#D15151",
    verticalAlign: "middle",
  },
  productIconUnselected: {
    color: "#6C7882",
    verticalAlign: "middle",
  },
};
