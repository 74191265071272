import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllcards } from "../../../utils/helper";
import { StHeadingTypography } from "../../Componentstyles";
import { AppLayout, CardItem } from "../../components";

export const Favourite = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const reducerData = useSelector((state) => state.favourites);
  const [favouritesList, setFavouritesList] = useState([]);

  useEffect(() => {
    if (reducerData?.favouriteIds && reducerData?.favouriteIds.length > 0) {
      setFavouritesList(getAllcards(reducerData?.favouriteIds));
    }
  }, [reducerData]);

  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <Box sx={{ margin: isMobile ? "1rem" : "3rem" }}>
        <Box sx={{ width: isMobile ? "95%" : "90%", margin: "auto" }}>
          <StHeadingTypography
            isNotDesktop={isNotDesktop}
            sx={{ pb: isMobile ? "0.4rem" : "1rem", textAlign: "center" }}
          >
            Favourites
          </StHeadingTypography>
        </Box>
        {favouritesList.length > 0 ? (
          <Grid container spacing={isMobile ? 2 : 5}>
            {favouritesList?.length > 0 &&
              favouritesList?.map((card, index) => (
                <Grid item xs={6} md={3}>
                  <CardItem isMobile={isMobile} {...card} key={index} />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Box sx={{ height: "35vh" }}>
            <StHeadingTypography
              isNotDesktop={isNotDesktop}
              sx={{ textAlign: "center" }}
            >
              No favourites found
            </StHeadingTypography>
          </Box>
        )}
      </Box>
    </AppLayout>
  );
};
