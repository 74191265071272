import { Box, Typography } from "@mui/material";
import React from "react";

export const PolicyCard = (props) => {
  const { feature, isMobile } = props;
  return (
    <Box
      sx={{
        padding: isMobile ? "0.5rem 2rem" : "3rem",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.25rem",
      }}
    >
      <Box>{feature.icon}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "1rem",
          }}
        >
          {feature.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "0.75rem",
          }}
        >
          {feature.description}
        </Typography>
      </Box>
    </Box>
  );
};
