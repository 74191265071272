import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Typography } from "@mui/material";
import React from "react";
import { contactUsStyles } from "./ContactUsStyles";

export const ContactUsCard = (props) => {
  const { header, addressLine1, addressLine2, addressLine3, phone, email } =
    props;
  return (
    <Box sx={contactUsStyles.contactUsCard}>
      <Typography sx={contactUsStyles.cardHeader}>{header}</Typography>
      <Box sx={contactUsStyles.addressContent}>
        <LocationOnIcon sx={contactUsStyles.subHeader} />
        <Box>
          <Typography sx={contactUsStyles.subHeader}>{addressLine1}</Typography>
          <Typography sx={contactUsStyles.subHeader}>{addressLine2}</Typography>
          <Typography sx={contactUsStyles.subHeader}>{addressLine3}</Typography>
        </Box>
      </Box>
      <Box sx={contactUsStyles.addressContent}>
        <PhoneIcon sx={contactUsStyles.subHeader} />
        <Box>
          <Typography sx={contactUsStyles.subHeader}>{phone}</Typography>
        </Box>
      </Box>
      <Box sx={contactUsStyles.addressContent}>
        <EmailIcon sx={contactUsStyles.subHeader} />
        <Box>
          <Typography sx={contactUsStyles.subHeader}>{email}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
