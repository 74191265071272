import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { PrivacyData } from "./PrivacyData";

export const PrivacyPolicy = (props) => {
  return (
    <AppLayout>
        <PolicyComponent data={PrivacyData} header="Privacy Policy" />
    </AppLayout>
  );
};
