import { Box } from "@mui/material";
import RouterApp from "./router/router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./config/store.js";

function App() {
  return (
    <Box>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <RouterApp />
        </PersistGate>
      </Provider>
    </Box>
  );
}

export default App;
