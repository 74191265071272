import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFavourites } from "../../../reducers/favouritesSlice";
import { ProductCardStyles } from "./productCardStyles";

export const ProductCard = ({ item, isMobile }) => {
  const router = useNavigate();
  const dispatch = useDispatch();

  // Handle the route
  const handleCardClick = () => {
    router(`/product/${item?.id}`);
  }
  return (
    <Card
      sx={{...ProductCardStyles?.root, height: isMobile ? "280px" : "auto"}}
      onClick={() => handleCardClick()}
    >
      <Box sx={isMobile ? ProductCardStyles.imageSectionMobile : ProductCardStyles.imageSection}>
        {/* <Box sx={ProductCardStyles.imageAlign}>
          <IconButton
            sx={{
              padding: "5px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setFavourites(item?.id));
            }}
          >
            {true ? (
              <FavoriteIcon sx={ProductCardStyles.productIcons} />
            ) : (
              <FavoriteBorderIcon
                sx={ProductCardStyles.productIconUnselected}
              />
            )}
          </IconButton>
        </Box> */}
        <Box sx={ProductCardStyles.image}>
          <img
            src={item?.cardImage}
            alt={item?.category}
            width={isMobile ? 160 : 220}
            height={isMobile ? 160 : 200}
          />
        </Box>
      </Box>
      <CardContent sx={isMobile ? ProductCardStyles.cardContentRootMobile : ProductCardStyles.cardContentRoot}>
        <Typography sx={{...ProductCardStyles.productName, fontSize: isMobile ? "10px" : "12px" }}>
          {item?.productName}
        </Typography>
        <Box sx={ProductCardStyles.priceAlign}>
          <Typography sx={{...ProductCardStyles.productPrice, fontSize: isMobile ? "14px" : "20px"}}>
            ₹{item?.price}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
