export const gridImageStyles = {
  gridContainer: {
    width: "70%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "640px",
    backgroundColor: "#f2f2f2",
  },
  gridContainerMobile: {
    width: "90%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "520px",
    backgroundColor: "#f2f2f2",
    pb: "2rem",
  },
  multiGridContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "1.2rem",
  },
  multiImage: {
    height: "260px",
    width: "100%",
  },
  multiImageMobile: {
    height: "150px",
    width: "100%",
  },
};
