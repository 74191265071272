export const routes = {
    intro:"/",
    home:"/",
    category:"/category/:categoryId",
    product:"/product/:productId",
    favourites:"/favourites",
    cart:"/cart",
    aboutUs:"/aboutus",
    contactUs:"/contactus",
    termConditions:"/terms-conditions",
    shipping:"/shipping-policy",
    returnCancel:"/return-refund-cancellation-policy",
    privacyPolicy:"/privacy-policy",
    intellectualProperty:"/intellectual-property",
    disclaimer:"/disclaimer",
}