import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { intellectualPropertyData } from "./IntellectualPropertyData";
import { useMediaQuery, useTheme } from "@mui/material";

export const IntellectualProperty = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <PolicyComponent data={intellectualPropertyData} header="Intellectual Property" />
    </AppLayout>
  );
};
