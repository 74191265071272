export const LandingPageStles = {
  healthSection: {
    background:
      "linear-gradient(180deg, rgba(218,255,230,1) 0%, rgba(154,244,175,1) 35%, rgba(255,255,255,1) 100%)",
  },
  productCards: {
    width: "100%",
    // maxWidth: "1200px",
    marginInline: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "3rem",
    flexWrap: "wrap",
  },
  productTab: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "none",
  },
  productSectionRoot: {
    background: "#fff",
    padding: "2rem 2.5rem 4rem 2.5rem",
  },
  productSectionRootMobile: {
    background: "#fff",
    padding: "0rem 1rem",
  },
  topcategoriesRoot: {
    background: "#fff",
    padding: "2rem 4rem 2rem 4rem",
    width: "100%",
  },
  topcategoriesRootMobile: {
    background: "#fff",
    padding: "0rem 2rem",
  },
  headers: {
    textAlign: "center",
    paddingBlock: "2rem",
  },
  headersMobile: {
    textAlign: "center",
    padding: "1rem 1rem 0rem",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#2C9763 !important",
      bottom: "0px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      fontFamily: "Poppins",
      fontSize: "18px",
      minWidth: "90px",
      fontWeight: 600,
      textTransform: "none",
      padding: "12px 16px",
    },
  },
  tabsMobile: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#2C9763 !important",
      bottom: "8px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-root": {
      fontFamily: "Poppins",
      fontSize: "13px",
      minWidth: "50px",
      fontWeight: 600,
      textTransform: "none",
      padding: "0.4rem",
    },
  },
  commonHeadersAlign: {
    textAlign: "center",
    py: "2rem",
  },
  featuresBox: {
    textAlign: "center",
    border: "none",
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1), 
          0px 1px 4px rgba(0, 0, 0, 0.12), 
          0px 2px 4px rgba(0, 0, 0, 0.14)`,
    marginInline: "3rem",
    filter: "drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.3))",
    borderRadius: "8px",
  },
};
