export const NavbarStyles = {
  desktopRoot: {
    background: "white",
    paddingInline: "1rem",
    paddingBlock: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2rem",
    boxShadow: `0px 20px 50px 0px #30363C1E`,
    cursor: "pointer",
    position:"fixed",
    zIndex:100000,
    width: "100%"
  },
  align: {
    display: "flex",
    flexDirection: "row",
  },
  mobileRoot: {
    background: "white",
    borderRadius: "8px",
    paddingInline: "0.75rem",
    paddingBlock: "0.8rem",
    boxShadow: `0px 20px 50px 0px #30363C1A`,
    cursor: "pointer",
  },
  mobileAlign: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobileMenus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1rem",
    marginBlock: "1rem",
  },
};
