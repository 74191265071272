import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCategoryListingData,
  getDiscription,
  toPascalCase,
} from "../../../utils/helper";
import { StHeadingTypography, StTypography } from "../../Componentstyles";
import { AppLayout, CardItem } from "../../components";
import { categoryProductsStyles } from "./CategoryProductsStyles";

export const CategoryProducts = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    setCategoryData(getCategoryListingData(categoryId));
  }, [categoryId]);

  const getNameWithoutQueryType = (name) => {
    const strippedName = toPascalCase(name);
    const removeDash = strippedName.replace(/[-]/g, ' ');
    return removeDash;
  }

  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      {categoryData?.length > 0 ? (
        <Box sx={{ margin: isMobile ? "1rem" : "3rem" }}>
          <Box sx={{ width: isMobile ? "95%" : "90%", margin: "auto" }}>
            <StHeadingTypography
              isNotDesktop={isNotDesktop}
              sx={{ pb: isMobile ? "0.4rem" : "1rem", textAlign: "center" }}
            >
              {getNameWithoutQueryType(categoryId)}
            </StHeadingTypography>
            <StTypography
              isNotDesktop={isNotDesktop}
              sx={{
                ...categoryProductsStyles.description,
                width: isMobile ? "90%" : "70%",
              }}
            >
              {getDiscription(categoryId)}
            </StTypography>
            <Grid container spacing={isMobile ? 2 : 5}>
              {categoryData?.length > 0 &&
                categoryData?.map((card, index) => (
                  <Grid item xs={6} md={3}>
                    <CardItem isMobile={isMobile} {...card} key={index} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box sx={categoryProductsStyles.noProductsFound}>No products found</Box>
      )}
    </AppLayout>
  );
};
