export const returnCancelData = [
  {
    header: "Return Policy",
    data: `We at karupatti.com strive to provide the best possible experience while you shop with us online. There is no option to return orders, if at all you are not satisfied with your purchase, complete assistance would be given to you regarding the same.\n
            For any other queries, all you need is to drop an email at bkm@karupatti.com`,
  },
  {
    header: "Cancellation Policy",
    data: `In order to get your order delivered to you soon, we process the orders same day. As long as your order has not been processed, we can cancel it and refund your amount. Please get in touch with our customer support team to cancel your order. The refund will be initiated in the form of voucher that will be emailed to you immediately. However, the cancellation of orders will be done only if they are respected to below listed terms and conditions:\n
    • You can cancel your orders within one hour of booking the same order. You are requested to immediately email at support@karupatti.com  along with your order number for any cancellations to be made.\n
    Your orders can also be cancelled if karupatti.com team finds your payments have not been received. Check below for further details:\n
    • Non receipt of Payment- In case the payment for the order is not made karupatti.com will automatically cancel the order. An email notification is sent to you when we cancel the order.\n
    <div style="font-weight: bold">For specific queries on return and cancellations, please read the FAQs.</div>`,
  },
];
