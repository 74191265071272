import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { CartStyles } from "./CartStyles";
import { cardStyles } from "../../components/Card/CardStyles";

export const CartSection = (props) => {
  const { cardData, handleChange, handleRemove, isMobile } = props;

  return (
    <Box sx={{ px: isMobile ? "1.5rem" : "3rem" }}>
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} md={8}>
          {!isMobile ? (
            <Box sx={CartStyles.cartContainer}>
              {cardData?.map((item, index) => (
                <Box
                  key={item?.id}
                  sx={{
                    ...CartStyles.cartContainerContent,
                    pb: index === cardData?.length - 1 ? "0rem" : "1rem",
                    borderBottom:
                      index === cardData?.length - 1
                        ? "none"
                        : "1px solid #e6e6e6",
                  }}
                >
                  <Box key={item?.id} sx={{ display: "flex", gap: "1rem" }}>
                    <Box sx={{ backgroundColor: "#f2f2f2" }}>
                      <img
                        src={item?.cardImage}
                        alt={item?.category}
                        width={150}
                        height={150}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                      }}
                    >
                      <Typography variant="h4">{item?.productName}</Typography>
                      <Box>
                        {" "}
                        Price of each quantity:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          ₹{item?.price}
                        </span>
                      </Box>
                      <Box sx={CartStyles.quantityContainer}>
                        <Typography sx={{ pt: "0.2rem" }}>
                          Quantity:{" "}
                        </Typography>
                        <TextField
                          size="small"
                          sx={CartStyles.qunatityTextField}
                          onChange={(e) => {
                            handleChange(item?.id, e.target.value);
                          }}
                          value={item?.quantity}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => handleRemove(item?.id)}
                        sx={CartStyles.removeButton}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="h4">
                      Price: ₹{item?.price * item?.quantity}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box sx={{ ...CartStyles.cartContainer, p: "1rem" }}>
              {cardData?.map((item, index) => (
                <Box
                  key={item?.id}
                  sx={{
                    ...CartStyles.cartContainerContent,
                    pb: index === cardData?.length - 1 ? "0rem" : "1rem",
                    borderBottom:
                      index === cardData?.length - 1
                        ? "none"
                        : "1px solid #e6e6e6",
                  }}
                >
                  <Box>
                    <Box sx={{ backgroundColor: "#f2f2f2" }}>
                      <img
                        src={item?.cardImage}
                        alt={item?.category}
                        width={120}
                        height={100}
                      />
                    </Box>
                    <Box sx={{ ...CartStyles.quantityContainer, pt: "0.4rem" }}>
                      <Typography sx={{ pt: "0.2rem" }}>Quantity: </Typography>
                      <TextField
                        size="small"
                        sx={CartStyles.qunatityTextField}
                        onChange={(e) => {
                          handleChange(item?.id, e.target.value);
                        }}
                        value={item?.quantity}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.65rem",
                    }}
                  >
                    <Typography variant="h4" sx={{ fontSize: "14px" }}>
                      {item?.productName}
                    </Typography>
                    <Box>
                      <Typography sx={{ fontSize: "12px" }}>
                        Price of each quantity:{" "}
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                          ₹{item?.price}
                        </span>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h4">
                        Price: ₹{item?.price * item?.quantity}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => handleRemove(item?.id)}
                      sx={CartStyles.removeButton}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4} sx={{ pt: 0 }}>
          <Box sx={CartStyles.totalSide}>
            <Typography
              variant="h4"
              sx={{ borderBottom: "1px solid #e6e6e6", pb: "1rem" }}
            >
              Order Summary
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Price ({cardData?.length} item)</Typography>
              <Typography>
                ₹{cardData?.reduce((a, b) => a + b.price * b.quantity, 0)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Discount</Typography>
              <Typography>₹0</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Delivery Charge</Typography>
              <Typography>₹0</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #e6e6e6",
                pt: "1rem",
              }}
            >
              <Typography variant="h4">Total Amount</Typography>
              <Typography variant="h4">
                ₹{cardData?.reduce((a, b) => a + b.price * b.quantity, 0)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
