import { useMediaQuery, useTheme } from "@mui/material";
import { StBlockContainer } from "../Componentstyles";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

const Navbar = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StBlockContainer>
      {isDesktop ? <DesktopNavbar /> : <MobileNavbar />}
    </StBlockContainer>
  );
};

export default Navbar;
