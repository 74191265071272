import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
  typography: {
    fontFamily: "Poppins Medium, sans-serif", // 'Roboto, sans-serif
    fontWeight: 400,
    h1: {
      fontFamily: "Poppins Bold, sans-serif",
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Poppins Semibold, sans-serif",
      fontSize: 14,
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Poppins Medium, sans-serif",
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 12,
    },
    h6: {
      fontFamily: "Poppins, sans-serif",
      fontSize: 10,
      fontWeight: 500,
    }
  },
  borderColor: {
    main: "#F1F5F9",
  },
  textColor: {
    main: "#808080",
  },
  headingTextColor: {
    main: "#404040",
  },
  blackColor: {
    main: "#000",
  },
  stepperColor: {
    main: "#f7f7f7",
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#808080",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Poppins';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap") format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
    },
  },
});
