import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export const CustomBreadcrumbsNavigation = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { breadCrums } = props;
  const router = useNavigate();

  const handleClick = (route) => {
    router(`/${route}`);
  };

  const links = breadCrums.map((link, index) => (
    <Box
      underline="hover"
      key={link.name}
      color="inherit"
      onClick={() => handleClick(link.route)}
    >
      <Typography
        sx={{
          cursor: "pointer",
          color: index === breadCrums.length - 1 ? "black" : "black",
          fontSize: isMobile ? "0.8rem" : "1rem",
          fontFamily: "Poppins",
          fontWeight: index === breadCrums.length - 1 ? "regular" : "bold", 
        }}
      >
        {link.name}
      </Typography>
    </Box>
  ));

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {links}
      </Breadcrumbs>
    </Stack>
  );
};
