import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { defaultTheme } from "./config/theme";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#30363C",
//     },
//     secondary: {
//       main: "#EBEEF1",
//     },
//   },
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
