import {
  HeadsetMic,
  LocalShipping,
  Replay,
  Verified,
} from "@mui/icons-material";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { PolicyCard } from "../../components";
import { StHeadingTypography } from "../../Componentstyles";
import { LandingPageStles } from "./LandPageStyles";

export const FeatureSection = (props) => {
  const { isMobile } = props;
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));

  const features = [
    {
      icon: <LocalShipping fontSize="large" sx={{ color: "#2C9763" }} />,
      title: "Free home delivery",
      description: "Provide free home delivery for all product over $100",
    },
    {
      icon: <Verified fontSize="large" sx={{ color: "#2C9763" }} />,
      title: "Quality Products",
      description: "We ensure the product quality that is our main goal",
    },
    {
      icon: <Replay fontSize="large" sx={{ color: "#2C9763" }} />,
      title: "3 Days Return",
      description: "Return product within 3 days for any product you buy",
    },
    {
      icon: <HeadsetMic fontSize="large" sx={{ color: "#2C9763" }} />,
      title: "Online Support",
      description: "We ensure the product quality that you can trust easily",
    },
  ];

  return (
    <Box sx={{background:"#fff"}}>
      <StHeadingTypography
        isNotDesktop={isMobile}
        sx={LandingPageStles.commonHeadersAlign}
      >
        Why Choose Us
      </StHeadingTypography>
      <Paper
        elevation={3}
        sx={{
          ...LandingPageStles.featuresBox,
          margin: isMobile ? "0rem 2rem 2rem" : "0rem 2rem 2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
            flexDirection: isNotDesktop ? "column" : "row",
            py: isMobile ? "1.5rem" : "0rem",
          }}
        >
          {features.map((feature, index) => (
            <PolicyCard isMobile={isMobile} key={index} feature={feature} />
          ))}
        </Box>
      </Paper>
    </Box>
  );
};
