import { Box, Button, Grid, Typography } from "@mui/material";
import PageNotFoundImage from "../../assets/pageNotFoundImage";
import { pageNotFoundStyle } from "./style";
import Navbar from "../../Navbar/Navbar";
import { StButton } from "../../Componentstyles";

export function PageNotFound(props) {
  const { className = "", sx = {}, ...rest } = props;

  return (
    <Box
      sx={[
        {
          ...pageNotFoundStyle.rootSx,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={`${className}`}
      {...rest}
    >
      <Navbar />
      <Box
        sx={{ pt: "10rem", width: "60%", margin: "auto", textAlign: "center" }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            textAlign={"center"}
            justifyContent="center"
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PageNotFoundImage />
            </Box>
            <Box textAlign={"center"} sx={{ py: "1rem" }}>
              <Typography variant="h7" sx={pageNotFoundStyle.headerTextSx}>
                Page Not Found
              </Typography>
            </Box>
            <StButton sx={{ width: "5rem" }}>Back</StButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
