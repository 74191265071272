import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import XIcon from "@mui/icons-material/X";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import assetLogo from "../../assets/assetLogo.png";
import { StFooterIcons, StSecondaryFooterLinks } from "../../Componentstyles";
import logoFooter from "../../image/logoFooter.jpg";
import { footerStyles } from "./FooterStyles";
import { routes } from "../../../router/routes";
import { useNavigate } from "react-router-dom";
import { setSelectedPage } from "../../../reducers/favouritesSlice";
import { useDispatch } from "react-redux";

export const FooterComponent = () => {
  const theme = useTheme();
  const router = useNavigate();
  const dispatch = useDispatch();

  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));

  const footerCompanyData = [
    { name: "About Us", nav: routes.aboutUs },
    { name: "Contact Us", nav: routes.contactUs },
    { name: "All Products", nav: routes.home },
    // { name: "Faq", nav: "faq" },
    // { name: "Locations", nav: "location" },
  ];

  const footerServiceData = [
    { name: "Terms & conditions", nav: routes.termConditions },
    { name: "Shipping policy", nav: routes.shipping },
    {
      name: "Return, Refund And Cancellation Policy",
      nav: routes.returnCancel,
    },
    { name: "Privacy Policy", nav: routes.privacyPolicy },
    { name: "Intellectual Property", nav: routes.intellectualProperty },
    { name: "Disclaimer", nav: routes.disclaimer },
  ];

  const footerProductData = [
    { name: "Karupatti", nav: "/category/karupatti" },
    { name: "Jaggery", nav: "/category/jaggery" },
    { name: "Cold pressed oil", nav: "/category/cold-pressed-oil" },
    { name: "Murunga powder", nav: "/category/murunga-powder" },
  ];

  const handleFooterClick = (item) => {
    dispatch(setSelectedPage(item.name));
    router(item.nav);
  };

  return (
    <>
      <Box sx={footerStyles.root}>
        <Box sx={{ marginInline: "2rem" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img src={assetLogo} alt={"Logo"} height={30} />
              <Typography
                variant="h6"
                sx={{ ...footerStyles.subHeader, marginTop: "1rem" }}
              >
                Best karupatti makers was founded in the year 2015 by a group of
                visionaries with an idea to bring natural,healthy and
                traditional food products to the forefront.
              </Typography>
              <Box sx={footerStyles.addressContent}>
                <LocationOnIcon sx={footerStyles.subHeader} />
                <Box>
                  <Typography sx={footerStyles.subHeader}>
                    No.47/19, Chidambaram Street,
                  </Typography>
                  <Typography sx={footerStyles.subHeader}>
                    {" "}
                    Bharathi Puram, Chrompet.
                  </Typography>
                  <Typography sx={footerStyles.subHeader}>
                    Chennai – 600044
                  </Typography>
                </Box>
              </Box>
              <Box sx={footerStyles.addressContent}>
                <PhoneIcon sx={footerStyles.subHeader} />
                <Box>
                  <Typography sx={footerStyles.subHeader}>
                    +91-94455 11283 /+91 72000 56569
                  </Typography>
                </Box>
              </Box>
              <Box sx={footerStyles.addressContent}>
                <EmailIcon sx={footerStyles.subHeader} />
                <Box>
                  <Typography sx={footerStyles.subHeader}>
                    bkm@karupatti.com
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2}>
              <StSecondaryFooterLinks>Company</StSecondaryFooterLinks>
              {footerCompanyData?.map((val) => (
                <Box>
                  <Typography
                    onClick={() => handleFooterClick(val)}
                    sx={footerStyles.footerLink}
                  >
                    {val.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={6} md={2}>
              <StSecondaryFooterLinks>Our Products</StSecondaryFooterLinks>
              {footerProductData?.map((val) => (
                <Box>
                  <Typography
                    onClick={() => handleFooterClick(val)}
                    sx={footerStyles.footerLink}
                  >
                    {val.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xs={6} md={2}>
              <StSecondaryFooterLinks>
                Policy and Services
              </StSecondaryFooterLinks>
              {footerServiceData?.map((val) => (
                <Box>
                  <Typography
                    onClick={() => handleFooterClick(val)}
                    sx={footerStyles.footerLink}
                  >
                    {val.name}
                  </Typography>
                </Box>
              ))}
            </Grid>

            <Grid item xs={6} md={2}>
              <StSecondaryFooterLinks>
                Our Social Media Handles
              </StSecondaryFooterLinks>
              <Typography sx={footerStyles.subHeader}>
                Get our latest update on our social media handles.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isNotDesktop ? "center" : "flex-start",
                  gap: "0.5rem",
                }}
              >
                <StFooterIcons>
                  <FacebookIcon sx={{ color: "#EBEEF1" }} />
                </StFooterIcons>
                <StFooterIcons>
                  <XIcon sx={{ color: "#EBEEF1" }} />
                </StFooterIcons>
                <StFooterIcons>
                  <InstagramIcon sx={{ color: "#EBEEF1" }} />
                </StFooterIcons>
                <StFooterIcons>
                  <LinkedInIcon sx={{ color: "#EBEEF1" }} />
                </StFooterIcons>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* certifications */}
      <Box
        sx={{
          ...footerStyles.certificationContainer,
          flexDirection: isNotDesktop ? "column" : "row",
        }}
      >
        <Box
          sx={{
            ...footerStyles.copyRightContainer,
            justifyContent: isNotDesktop ? "center" : "flex-end",
          }}
        >
          <img src={logoFooter} alt={logoFooter} width={280} />
        </Box>
        <Box
          sx={{
            ...footerStyles.copyRightContainer,
            justifyContent: isNotDesktop ? "center" : "flex-start",
          }}
        >
          <Typography sx={footerStyles.copyRightText}>
            Copyright © 2024 Karupatti Makers. All rights reserved. Powered by
            Dexwox Innovations
          </Typography>
        </Box>
      </Box>
    </>
  );
};
