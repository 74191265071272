import { Box, Card } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedPage } from "../../../reducers/favouritesSlice";
import { StTypography } from "../../Componentstyles";
import { categoryStyles } from "./CategoryStyles";

export const CategoryCard = (props) => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { isMobile, item, isNotDesktop } = props;
  const handleCardClick = () => {
    dispatch(setSelectedPage(item?.name));
    router(`/${item?.route}`);
  };
  return (
    <Card
      sx={{
        ...categoryStyles.root,
        width: isMobile ?"270px" : "320px",
        height: isMobile ?  "380px" : "480px",
      }}
      onClick={handleCardClick}
    >
      <img
        src={item.image}
        alt="icon"
        width={isMobile ? "320px" : "320px"}
        height={isMobile ? "200px" : "230px"}
      ></img>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          padding: isMobile ? "0rem 0rem 2rem 0rem" : "0.4rem 0rem 2rem 0rem",
        }}
      >
        <StTypography
          sx={{
            fontSize: isMobile ? "12px" : "14px",
            fontWeight: 600,
          }}
        >
          {item.name}
        </StTypography>
        <StTypography
          isNotDesktop={isNotDesktop}
          sx={{
            fontSize: isMobile ? "12px" : "14px",
            lineHeight: isMobile ? "18px" : "24px",
          }}
        >
          {item?.description}
        </StTypography>
      </Box>
    </Card>
  );
};
