export const categoryStyles = {
  root: {
    padding: "0px !important",
    margin: "10px 16px",
    borderRadius: "8px",
    minHeight: "320px",
    filter: "drop-shadow(8px 8px 5px rgba(0, 0, 0, 0.3))",
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.1), 
              0px 1px 4px rgba(0, 0, 0, 0.12), 
              0px 2px 4px rgba(0, 0, 0, 0.14)`,
    "&:hover": {
      transform: "translateY(-5px)",
      transition: "transform 0.3s ease-in-out",
      cursor: "pointer",
      boxShadow: `0px 10px 20px rgba(0, 0, 0, 0.2), 
              0px 4px 8px rgba(0, 0, 0, 0.15), 
              0px 5px 10px rgba(0, 0, 0, 0.1)`,
    },
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px !important",
    gap: "1rem",
    // marginBlock: "2rem",
  },
  name: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "18px",
    color: "#000",
    textAlign: "center",
  },
  subName: {
    fontSize: "13px",
    color: "#000",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "13px",
  },
};
