export const categoryProductsStyles = {
  description: {
    textAlign: "center",
    pb: "1rem",
    width: "70%",
    margin: "auto",
  },
  noProductsFound: {
    height: "60vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
