import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { returnCancelData } from "./ReturnCancel";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export const ReturnCancel = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <PolicyComponent data={returnCancelData} header="Return, Refund and Cancellation policy" noHeaderSpace />
    </AppLayout>
  );
};
