import "@fontsource/varela-round";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { AppLayout, CustomCarousel, GridImageSection } from "../../components";
import { FeatureSection } from "./FeatureSection";
import { HealthBenefits } from "./HealthBenefits";
import "./LandingPage.css";
import { ProductSection } from "./ProductsSection";
import { TopCategories } from "./TopCategories";

export const LandingPage = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ backgroundColor: "#fff" }}>
        <AppLayout isNotDesktop={isNotDesktop}>
          <CustomCarousel type="banner" />
          <TopCategories isMobile={isMobile} isNotDesktop={isNotDesktop} />

          <GridImageSection isMobile={isMobile} header="Featured Products" />
          <ProductSection isNotDesktop={isNotDesktop} isMobile={isMobile} />

          <HealthBenefits isMobile={isMobile} />
          <FeatureSection isMobile={isMobile} />
        </AppLayout>
      </Box>
    </>
  );
};
