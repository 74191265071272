export const PrivacyData = [
  {
    header: "",
    data: `karupatti.com recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.\n
        This Privacy Policy explains the information practices that apply to personally identifiable information that we collect about you as an individual when you visit and/or use our website. Any information that we collect about you while you are visiting or using our website will be handled in accordance with this karupatti.com Privacy Policy and will not be shared except in accordance with this karupatti.com Privacy Policy. This Privacy Policy does not apply to information about you collected by our affiliates or third party websites that may post links or advertisements on or otherwise be accessible from the karupatti.com website. The information collected by these affiliates or third party websites is subject to their own privacy policies.`,
  },
  {
    header: "Information We Collect",
    data: `This section of our Privacy Policy describes the categories of information collected by karupatti.com. You have the option not to provide information, however, withholding information may prevent you from being able to use some of our website features.\n
        <span style="font-weight: bold">Information Collected Automatically:</span> Whenever you visit our website, we automatically collect some information about your transactions with us, and your use of our website. For example, we automatically collect your IP address, for karupatti.com internal use only, such as to help us diagnose problems with our server and administer our website. We also automatically track certain information about your activities on our website such as the type of web browser you are using and click stream data. If you are a registered karupatti.com customer, we link this automatically-collected data to the personally identifiable information we collect about you described below. We use this information to do internal research on your interests and to help us better understand and serve you.\n
        <span style="font-weight: bold">Information You Send To Us:</span> If you choose to provide us with personal information, such as by sending us an e-mail or by filling out a form with your personal information and submitting it to us through our website, we collect the personal information that you provide to us. For example, if you register or sign up for an account with us, we collect your name, e-mail address, and password. If you place an order with us, we collect the personally identifiable information that you provide to us such as your shipping, billing, and payment information.\n
        <span style="font-weight: bold">Information About Your Transactions With Us:</span> In addition, we collect your order information and purchase and payment history.`,
  },
  {
    header: "Cookies and Similar Files",
    data: `Our website uses “cookies” and files that are similar to cookies. Cookies are alphanumeric identifiers created by your browser at our request and stored in an approved and standardized place on your computer. By transferring these cookies, karupatti.com assigns you a unique customer code and record locater. Information about your activity on our website can then be included in your customer record, which is stored within the karupatti.com firewall. When you visit or return to our website, we read the cookie to identify you as one of our customers and help recall orders or preferences you have selected. The cookie also enables us to recall your past activities, post your account data, and tailor site elements and special offers to you. No personally identifiable information about you is stored on the cookie.\n
        Most Web browsers accept cookies by default, but allow users to reject cookies by changing the browser preferences. If you have set up your browser to reject cookies, some aspects of our website, including our Shopping Cart, will not work properly.\n
        This Privacy Policy covers the use of cookies and similar files by karupatti.com only and does not cover the use of cookies and similar files by any third party advertisers or websites linked to the karupatti.com website.\n
        We use third-party advertising companies to serve ads on our behalf. These companies may use a cookie or an action tag, also known as a Web Beacon or a 1 pixel .gif file, to track your response to their advertisement and to measure the effectiveness of their advertising or to award incentives or points to their members who respond to their advertisements. karupatti.com has no access to or control over cookies or action tags used by these third party companies. karupatti.com does not receive any personally identifiable information collected by these advertisers using action tags or cookies nor does karupatti.com share any of your personally identifiable information such as your name, telephone number or address with these companies except in accordance with this karupatti.com Privacy Policy.`,
  },
  {
    header: "Links To Third Party Products and Services",
    data: `While you are visiting or using the karupatti.com website, you may be presented with an opportunity to purchase third party products or services. These products and services are offered and supplied by independent companies not owned or operated by karupatti.com. If you click on one of the presented offers, you will be redirected to the site of the third party, and any information you provide in response to the offer will be collected and used by the third party and not by karupatti.com. Information you provide to the third party and any dealings you have with the third party will be governed by the privacy and other policies of that third party.`,
  },
  {
    header: "Website Security",
    data: `When you use or access your account information karupatti.com, offer the use of a secure server. The secure server software (SSL) encrypts all information you put in before it is sent to us. Furthermore, all of the customer data we collect is protected against unauthorized access. To prevent unauthorized access, maintain data accuracy, and ensure correct use of information, we will employ reasonable and current Internet security methods and technologies.\n
        karupatti.com authorizes only those persons who need to know personal information to administer your account, to provide or inform you about products and services, or to maintain, improve or administer our website to access your personal information.`,
  },
];
