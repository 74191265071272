import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllcards } from "../../../utils/helper";
import { StHeadingTypography } from "../../Componentstyles";
import { AppLayout } from "../../components";
import { CartSection } from "./CartSection";
import { removeCart } from "../../../reducers/favouritesSlice";

export const Cart = () => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const reducerData = useSelector((state) => state.favourites);
  const [cartList, setCartList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reducerData?.cartIds && reducerData?.cartIds.length > 0) {
      let data = getAllcards(reducerData?.cartIds);
      data = data.map((item) => {
        return {
          ...item,
          quantity: 1,
        };
      });
      setCartList(data);
    }
  }, [reducerData]);

  const handleChange = (id, value) => {
    let data = [...cartList];
    let changedObject = data.find((item) => item.id === id);
    changedObject.quantity = value;
    data.splice(data.indexOf(changedObject), 1, changedObject);
    setCartList(data);
  };

  const handleRemove = (id) => {
    let data = [...cartList];
    data = data.filter((item) => item.id !== id);
    setCartList(data);
    dispatch(removeCart(id));
  };

  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <Box sx={{ py: "2rem" }}>
        <Box sx={{ width: "90%", mb: "1rem", pl: isMobile ? "1.5rem" : "3rem" }}>
          <Typography variant="h4">
            Your Shopping Cart has {cartList.length} items
          </Typography>
        </Box>
        {cartList.length > 0 ? (
          <>
            <CartSection
              isMobile={isMobile}
              cardData={cartList}
              handleChange={handleChange}
              handleRemove={handleRemove}
            />
          </>
        ) : (
          <Box sx={{ height: "35vh" }}>
            <StHeadingTypography
              isNotDesktop={isNotDesktop}
              sx={{ textAlign: "center" }}
            >
              Nothing added to Cart yet
            </StHeadingTypography>
          </Box>
        )}
      </Box>
    </AppLayout>
  );
};
