import { Box } from "@mui/material";
import React from "react";
import { categories } from "../../../utils/helper";
import { SlickCarouselComponent } from "../../components";
import { CategoryCard } from "../../components/CategoryCard";
import { StHeadingTypography } from "../../Componentstyles";
import { LandingPageStles } from "./LandPageStyles";

export const TopCategories = (props) => {
  const { isMobile, isNotDesktop } = props;
  return (
    <Box
      sx={
        isMobile
          ? LandingPageStles.topcategoriesRootMobile
          : LandingPageStles.topcategoriesRoot
      }
    >
      <StHeadingTypography
        isNotDesktop={isMobile}
        sx={{
          ...LandingPageStles.commonHeadersAlign,
          pb: isMobile ? "0rem" : "2rem",
        }}
      >
        Top Categories
      </StHeadingTypography>
      <Box
        sx={{
          display: "flex",
          gap: isMobile ? "1.6rem" : "4rem",
          justifyContent: "left",
          alignItems: "flex-start",
          padding: "0rem 2rem 2rem 0rem",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {categories.map((item, index) => (
          <Box sx={{ width: isMobile ? "270px" : "300px" }}>
            <CategoryCard
              isNotDesktop={isNotDesktop}
              key={index}
              item={item}
              isMobile={isMobile}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
