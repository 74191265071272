export const CartStyles = {
  totalSide: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.4)",
    p: "1rem",
    border: "1px solid #f2f2f2",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  cartContainer: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.4)",
    p: "2rem",
    border: "1px solid #f2f2f2",
    maxHeight: "70vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#f2f2f2",
      borderRadius: "0.5rem",
    },
  },
  cartContainerContent: {
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    pt: "1rem",
  },
  qunatityTextField: {
    width: "60px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
        textAlign: "center",
      },
      "&:hover fieldset": {
        borderColor: "green",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  quantityContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  removeButton: {
    textTransform: "none",
    backgroundColor: "#2C9763",
    maxWidth:"225px",
    "&:hover": {
      backgroundColor: "#2C9763",
    },
  },
};
