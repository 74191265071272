import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCart,
  setFavourites,
  setSelectedPage,
} from "../../../reducers/favouritesSlice";
import healthbenefitsLeaves from "../../image/healthbenefitsLeaves.png";
import { cardStyles } from "./CardStyles";

export const CardItem = (props) => {
  const { isMobile } = props;
  const router = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);
  const [isCart, setIsCart] = useState(false);
  const reducerData = useSelector((state) => state.favourites);

  const handleCardClick = () => {
    router(`/product/${props?.id}`);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setIsFavourite(reducerData?.favouriteIds?.includes(props?.id));
    setIsCart(reducerData?.cartIds?.includes(props?.id));
  }, [reducerData.cartIds, reducerData.favouriteIds, props?.id]);

  const createProductName = (name) => {
    const nameArray = name.split("-");
    let nameString = name;
    const avgLength = isMobile ? 35 : 55;
    if (nameArray[0]?.length > avgLength) {
      nameString = nameArray?.[0]?.slice(0,isMobile ? 28 : 38) + "... -" + nameArray?.[1];
    }
    return nameString;
  };

  return (
    <Card
      sx={{
        ...cardStyles.cardContainer,
        maxWidth: isMobile ? "172px" : `calc(100vw/${props?.length})`,
        minWidth: isMobile ? "168px" : "284px",
      }}
      onClick={handleCardClick}
    >
      <Box sx={cardStyles.imageContainer}>
        {props?.healthbenefits === "eco-friendly" ? (
          <Box
            sx={{
              ...cardStyles.healthBenifitsIcon,
              top: isMobile ? "6px" : "15px",
              right: isMobile ? "6px" : "15px",
            }}
          >
            <Box sx={{ justifySelf: "flex-end" }}>
              <img
                src={healthbenefitsLeaves}
                alt={healthbenefitsLeaves}
                width={isMobile ? 18 : 26}
              />
            </Box>
          </Box>
        ) : (
          " "
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={props?.cardImage}
            alt={props?.category}
            width={isMobile ? 180 : 280}
            height={isMobile ? 160 : 250}
          />
        </Box>
      </Box>
      <CardContent
        sx={{
          ...cardStyles.cardContent,
          padding: isMobile ? "0.4rem 0.8rem" : "1rem",
        }}
      >
        <Typography sx={cardStyles.productName}>
          {createProductName(props?.productName)}
        </Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Typography
            sx={{
              ...cardStyles.productPrice,
              fontSize: isMobile ? "15px" : "20px",
              fontWeight: isMobile ? "700" : "600",
            }}
          >
            ₹{props?.price}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          ...cardStyles.cardAction,
          padding: isMobile ? "0rem 0.4rem 0.8rem" : "0.8rem",
        }}
      >
        <IconButton
          sx={cardStyles.iconButton}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setFavourites(props?.id));
          }}
        >
          {isFavourite ? (
            <FavoriteIcon
              sx={{
                color: "#D15151",
                verticalAlign: "middle",
                fontSize: isMobile ? "16px" : "30px",
              }}
            />
          ) : (
            <FavoriteBorderIcon
              sx={{
                color: "#6C7882",
                verticalAlign: "middle",
                fontSize: isMobile ? "16px" : "30px",
              }}
            />
          )}
        </IconButton>
        {isCart ? (
          <Button
            variant="contained"
            sx={{
              ...cardStyles.cardButtons,
              padding: isMobile ? " 8px" : "6px 16px",
              height: isMobile ? "28px" : "42px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSelectedPage("Cart"));
              router("/cart");
            }}
          >
            View Cart
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              ...cardStyles.cardButtons,
              padding: isMobile ? " 8px" : "6px 16px",
              height: isMobile ? "28px" : "42px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setCart(props?.id));
            }}
          >
            Add to cart
          </Button>
        )}
      </CardActions>
    </Card>
  );
};
