import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import karupattiImage from "../../image/featuredImg2.jpeg";
import murungaPowderImage from "../../image/featuredImg3.jpeg";
import coldPressedOilImage from "../../image/featuredImg1.jpeg";
import { gridImageStyles } from "./GridImageStyles";
import { StHeadingTypography } from "../../Componentstyles";

export const GridImageSection = (props) => {
  const { header, isMobile } = props;
  return (
    <Box sx={{ backgroundColor: "#f2f2f2" }}>
      {header && (
        <StHeadingTypography
          isNotDesktop={isMobile}
          sx={{
            textAlign: "center",
            pt: "2rem",
            pb: isMobile ? "1rem" : "0rem",
          }}
        >
          {header}
        </StHeadingTypography>
      )}

      <Box
        sx={
          isMobile
            ? gridImageStyles.gridContainerMobile
            : gridImageStyles.gridContainer
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <img
                src={murungaPowderImage}
                alt="Murunga Powder"
                style={{
                  ...gridImageStyles.multiImage,
                  height: isMobile ? "300px" : "540px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={gridImageStyles.multiGridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <img
                  src={coldPressedOilImage}
                  alt="Murunga Powder"
                  style={
                    isMobile
                      ? gridImageStyles.multiImageMobile
                      : gridImageStyles.multiImage
                  }
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <img
                  src={karupattiImage}
                  alt="Murunga Powder"
                  style={
                    isMobile
                      ? gridImageStyles.multiImageMobile
                      : gridImageStyles.multiImage
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <img
                  src={coldPressedOilImage}
                  alt="Murunga Powder"
                  style={
                    isMobile
                      ? gridImageStyles.multiImageMobile
                      : gridImageStyles.multiImage
                  }
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <img
                  src={karupattiImage}
                  alt="Murunga Powder"
                  style={
                    isMobile
                      ? gridImageStyles.multiImageMobile
                      : gridImageStyles.multiImage
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
