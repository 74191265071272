export const footerStyles = {
  root: { background: "#071c1f", width: "100%", paddingBlock: "1rem" },
  copyRightText: {
    color: "#6C7882",
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    textAlign: "center",
    fontWeight: 500,
  },
  copyRightContainer: {
    display: "flex",
    marginBlock: "0.5rem",
    alignItems: "center",
  },
  certificationContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginInline: "2rem",
    marginBlock: "0.5rem",
  },
  subHeader: {
    fontSize: "12px",
    color: "#EBEEF1",
    lineHeight: 2,
    fontFamily: "Poppins",
  },
  addressContent: {
    display: "flex",
    gap: "0.4rem",
    mt: "0.6rem",
    alignItems: "center",
  },
  footerLink: {
    fontSize: "12px",
    color: "#EBEEF1",
    lineHeight: 2,
    fontFamily: "Poppins",
    mb: 1.5,
    cursor: "pointer",
  },
};
