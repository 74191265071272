export const cardStyles = {
  mobileCardStyles: {
    display: "flex",
    flexDirection: "row",
    gap: "1.5rem",
    flexWrap: "wrap",
    overflowY: "scroll",
    justifyContent: "center",
  },
  deskstopCardStyles: {
    display: "flex",
    flexWrap: "wrap",
    gap: "2.8rem",
    justifyContent: "start",
    marginInline: "auto",
  },
  cardContainer: {
    minWidth: `284px`,
    display: "flex",
    flexDirection: "column",
    gap: "0.2rem",
    border: `1px solid #f2f2f2`,
    boxShadow: `0px 4px 8px rgba(44, 151, 99, 0.1), 
    0px 1px 4px rgba(44, 151, 99, 0.12), 
    0px 2px 4px rgba(44, 151, 99, 0.14)`,
    "&:hover": {
      transform: "translateY(-5px)",
      transition: "transform 0.3s ease-in-out",
      boxShadow: `0px 10px 20px rgba(44, 151, 99, 0.2), 
      0px 4px 8px rgba(44, 151, 99, 0.15), 
      0px 5px 10px rgba(44, 151, 99, 0.1)`,
      cursor: "pointer",
    },
  },
  productName: {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "12px",
    color: "#000",
    minHeight: "40px",
    maxWidth: "204px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  productPrice: {
    fontSize: "20px",
    color: "#30363C",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "24px",
  },
  cardAction: {
    padding: "0.8rem",
    margin: "0px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    color: "#CED5DB",
    borderRadius: "5px",
    border: "1px solid #CED5DB",
    padding: "5px",
  },
  cardButtons: {
    textTransform: "none",
    background: "#2C9763",
    height: "42px",
    width: "100%",
    borderRadius: "8px",
    fontWeight: "600",
    "&:hover": {
      background: "#2C9763",
    },
  },
  cardContent: {
    margin: 0,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.4rem",
  },
  imageContainer: {
    backgroundColor: "#f2f2f2",
    maxHeight: "420px",
    position: "relative",
  },
  healthBenifitsIcon: {
    position: "absolute",
  },
};
