import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { termsData } from "./TermsData";
import { useMediaQuery, useTheme } from "@mui/material";

export const TermsConditions = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <PolicyComponent data={termsData} header="Terms & Conditions" />
    </AppLayout>
  );
};
