import { Box, Grid } from "@mui/material";
import React from "react";
import HealthBeniftAromaImage from "../../../features/image/healthbenefitsAroma.png";
import HealthBenifitArrowImage from "../../../features/image/healthbenefitsArrow.png";
import HealthBenifitAgeGroupImage from "../../../features/image/healthbenefitsGroup.png";
import HeathBeniftshealthcareImage from "../../../features/image/healthbenefitsHeart.png";
import HealthBinifitLeafImage from "../../../features/image/healthbenefitsLeaf.png";
import HealthBenifitsLeavesImage from "../../../features/image/healthbenefitsLeaves.png";
import HealthBenifitvitaminImage from "../../../features/image/healthbenefitsVitamin.png";
import { StHeadingTypography } from "../../Componentstyles";
import { BenefitsCard } from "../../components";
import { LandingPageStles } from "./LandPageStyles";

const items = [
  {
    name: "Low glycemic",
    price: "Healthy substitute",
    image: HealthBenifitArrowImage,
  },
  {
    name: "High load of minerals",
    price: "Fe, Ca, Mg, B-complex, B12",
    image: HealthBenifitAgeGroupImage,
  },
  {
    name: "Source of vitamin B12",
    price: "Enriched vitamin B12",
    image: HealthBenifitvitaminImage,
  },
  {
    name: "Medicinal properties",
    price: "Healthy substitute",
    image: HeathBeniftshealthcareImage,
  },
  {
    name: "Has great taste & aroma",
    price: "chocolate & caramel flavour",
    image: HealthBeniftAromaImage,
  },
  {
    name: "Consumed by all age groups",
    price: "Good for children",
    image: HealthBenifitAgeGroupImage,
  },
  {
    name: "Eco-friendly sustainable source of sweetner",
    price: "",
    image: HealthBenifitsLeavesImage,
  },
  {
    name: "Health mix",
    price: "Starts from ₹135",
    image: HealthBinifitLeafImage,
  },
];

export const HealthBenefits = (props) => {
  const { isMobile } = props;

  return (
    <Box sx={{pb:"2rem",backgroundColor:"#f2f2f2"}}>
      <StHeadingTypography
        isNotDesktop={isMobile}
        sx={LandingPageStles.commonHeadersAlign}
      >
        Health benefits
      </StHeadingTypography>
      <Box sx={{ width: isMobile ? "90%" : "80%", m: "auto" }}>
        <Grid container spacing={isMobile ? 2 : 4}>
          {items.map((item, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <BenefitsCard isMobile={isMobile} index={index} item={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
