import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { AppLayout, PolicyComponent } from "../../components";
import { shippingData } from "./ShippingData";

export const Shipping = (props) => {
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppLayout isNotDesktop={isNotDesktop}>
      <PolicyComponent data={shippingData} header="Shipping Policy" noHeaderSpace />
    </AppLayout>
  );
};
